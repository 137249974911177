const appConfig = {
  key: 'tms',
  apiPrefix: 'api',
  productSite: 'https://www.oos-studio.com',
  authenticatedEntryPath: '/dashboard',
  unAuthenticatedEntryPath: '/sign-in',
  onboardingEntryPath: '/onboarding',
  issuePath: process.env.REACT_APP_ISSUE_ID_PATH ? '/' + process.env.REACT_APP_ISSUE_ID_PATH : '',
  api: {
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    graphQLPath: process.env.REACT_APP_API_GRAPHQL_PATH,
  },
}

export default appConfig
