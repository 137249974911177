import React from 'react'
import { RouteConfig } from './index'
import ButtonExample from '../../views/dev/showcase/ButtonExample'
import CardExample from '../../views/dev/showcase/CardExample'
import IconExample from '../../views/dev/showcase/IconExample'
import TypographyExample from '../../views/dev/showcase/TypographyExample'
import TableExample from '../../views/dev/showcase/TableExample'
import FormExample from '../../views/dev/showcase/FormExample'
import DatePickerExample from '../../views/dev/showcase/DatePickerExample'
import CalendarExample from '../../views/dev/showcase/CalendarExample'
import AvatarExample from '../../views/dev/showcase/AvatarExample'
import AvatarGroupExample from '../../views/dev/showcase/AvatarGroupExample'
import BadgeExample from '../../views/dev/showcase/BadgeExample'
import DropdownExample from '../../views/dev/showcase/DropdownExample'
import AlertExample from '../../views/dev/showcase/AlertExample'
import IndicatorExample from '../../views/dev/showcase/IndicatorExample'
import DrawerExample from '../../views/dev/showcase/DrawerExample'
import UpdatedIconExample from '../../views/dev/showcase/UpdatedIconExample'
import CheckboxExample from '../../views/dev/showcase/CheckboxExample'
import UserDropdownExample from '../../views/dev/showcase/UserDropdownExample'
import InputExample from '../../views/dev/showcase/InputExample'
import BrandExample from '../../views/dev/showcase/BrandExample'
import ProgressLoaderExample from '../../views/dev/showcase/ProgressLoaderExample'
import TagExample from '../../views/dev/showcase/TagExample'
import SignInScreen from '../../views/auth/SignIn'
import ToastExample from '../../views/dev/showcase/ToastExample'
import TooltipExample from '../../views/dev/showcase/TooltipExample'
import DialogueExample from '../../views/dev/showcase/DialogueExample'
import SegmentExample from '../../views/dev/showcase/SegmentExample'
import SelectExample from '../../views/dev/showcase/SelectExample'
import PaginationExample from '../../views/dev/showcase/PaginationExample'
import DividerExample from '../../views/dev/showcase/DividerExample'
import HeaderExample from '../../views/dev/showcase/HeaderExample'
import StepsExample from '../../views/dev/showcase/StepsExample'
import SwitcherExample from '../../views/dev/showcase/SwitcherExample'
import RadioExample from '../../views/dev/showcase/RadioExample'
import NotificationDropdownItemExample from '../../views/dev/showcase/NotificationDropdownItemExample'
import NotificationDropdownButtonExample from '../../views/dev/showcase/NotificationDropdownButtonExample'
import Dev from '../../views/dev'
import NotificationDropdownHeaderExample from '../../views/dev/showcase/NotificationDropdownHeaderExample'
import TabsExample from '../../views/dev/showcase/TabsExample'
import NotificationDropdownFooterExample from '../../views/dev/showcase/NotificationDropdownFooterExample'
import NotificationDropdownExample from '../../views/dev/showcase/NotificationDropdownExample'
import ProgramDropdownExample from '../../views/dev/showcase/ProgramDropdownExample'
import UtilityToggleExample from '../../views/dev/showcase/UtilityToggleExample'
import SidePeekToggleExample from '../../views/dev/showcase/SidePeekToggleExample'
import SkeletonExample from '../../views/dev/showcase/SkeletonExample'

const devRoutes: RouteConfig[] = [
  {
    title: 'Dev',
    key: 'dev',
    path: '/',
    component: <Dev />,
  },
  {
    title: 'UI Button',
    key: 'uiButton',
    path: '/ui/button',
    component: <ButtonExample />,
  },
  {
    title: 'UI Icon',
    key: 'uiIcon',
    path: '/ui/icon',
    component: <IconExample />,
  },
  {
    title: 'theme Typography',
    key: 'themeTypography',
    path: '/theme/typography',
    component: <TypographyExample />,
  },
  {
    title: 'UI Table',
    key: 'uiTable',
    path: '/ui/table',
    component: <TableExample />,
  },
  {
    title: 'UI Form',
    key: 'uiForm',
    path: '/ui/form',
    component: <FormExample />,
  },
  {
    title: 'UI Date Picker',
    key: 'uiDatePicker',
    path: '/ui/date-picker',
    component: <DatePickerExample />,
  },
  {
    title: 'UI Calendar',
    key: 'uiCalendar',
    path: '/ui/calendar',
    component: <CalendarExample />,
  },
  {
    title: 'UI Avatar',
    key: 'uiAvatar',
    path: '/ui/avatar',
    component: <AvatarExample />,
  },
  {
    title: 'UI Avatar Group',
    key: 'uiAvatarGroup',
    path: '/ui/avatar-group',
    component: <AvatarGroupExample />,
  },
  {
    title: 'UI Badge',
    key: 'uiBadge',
    path: '/ui/badge',
    component: <BadgeExample />,
  },
  {
    title: 'UI Dropdown',
    key: 'uiDropdown',
    path: '/ui/dropdown',
    component: <DropdownExample />,
  },
  {
    title: 'UI Alert',
    key: 'uiAlert',
    path: '/ui/alert',
    component: <AlertExample />,
  },
  {
    title: 'UI Indicator',
    key: 'uiIndicator',
    path: '/ui/indicator',
    component: <IndicatorExample />,
  },
  {
    title: 'UI Drawer',
    key: 'uiDrawer',
    path: '/ui/drawer',
    component: <DrawerExample />,
  },
  {
    title: 'UI Icon Update',
    key: 'uiIconUpdate',
    path: '/ui/icon-update',
    component: <UpdatedIconExample />,
  },
  {
    title: 'UI Checkbox',
    key: 'uiCheckbox',
    path: '/ui/checkbox',
    component: <CheckboxExample />,
  },
  {
    title: 'Layout User Dropdown',
    key: 'layoutUserDropdown',
    path: '/layout/user-dropdown',
    component: <UserDropdownExample />,
  },
  {
    title: 'UI Input',
    key: 'uiInput',
    path: '/ui/input',
    component: <InputExample />,
  },
  {
    title: 'UI Brand',
    key: 'uiBrand',
    path: '/ui/brand',
    component: <BrandExample />,
  },
  {
    title: 'UI Progress Loader',
    key: 'uiProgressLoader',
    path: '/ui/progress-loader',
    component: <ProgressLoaderExample />,
  },
  {
    title: 'UI Tag',
    key: 'uiTag',
    path: '/ui/tag',
    component: <TagExample />,
  },
  {
    title: 'UI Steps',
    key: 'uiSteps',
    path: '/ui/steps',
    component: <StepsExample />,
  },
  {
    title: 'UI Switcher',
    key: 'uiSwitcher',
    path: '/ui/switcher',
    component: <SwitcherExample />,
  },
  {
    title: 'Layout Side',
    key: 'layoutSide',
    path: '/layout/side',
    component: <SignInScreen />,
  },
  {
    title: 'UI Toast',
    key: 'uiToast',
    path: '/ui/toast',
    component: <ToastExample />,
  },
  {
    title: 'UI Tooltip',
    key: 'uiTooltip',
    path: '/ui/tooltip',
    component: <TooltipExample />,
  },
  {
    title: 'UI Divider',
    key: 'uiDivider',
    path: '/ui/divider',
    component: <DividerExample />,
  },
  {
    title: 'UI Dialog',
    key: 'uiDialog',
    path: '/ui/dialog',
    component: <DialogueExample />,
  },
  {
    title: 'UI Segment',
    key: 'uiSegment',
    path: '/ui/segment',
    component: <SegmentExample />,
  },
  {
    title: 'UI Select',
    key: 'uiSelect',
    path: '/ui/select',
    component: <SelectExample />,
  },
  {
    title: 'UI Pagination',
    key: 'uiPagination',
    path: '/ui/pagination',
    component: <PaginationExample />,
  },
  {
    title: 'UI Tabs',
    key: 'uiTabs',
    path: '/ui/tabs',
    component: <TabsExample />,
  },
  {
    title: 'UI Radio',
    key: 'uiRadio',
    path: '/ui/radio',
    component: <RadioExample />,
  },
  {
    title: 'UI Skeleton',
    key: 'uiSkeleton',
    path: '/ui/skeleton',
    component: <SkeletonExample />,
  },
  {
    title: 'Layout Header',
    key: 'layoutHeader',
    path: '/layout/header',
    component: <HeaderExample />,
  },
  {
    title: 'Layout Utility Toggle',
    key: 'layoutUtilityToggle',
    path: '/layout/utility-toggle',
    component: <UtilityToggleExample />,
  },
  {
    title: 'Layout SidePeekToggle',
    key: 'layoutSidePeekToggle',
    path: '/layout/side-peek-toggle',
    component: <SidePeekToggleExample />,
  },
  {
    title: 'Layout Notification Dropdown Item',
    key: 'layoutNotificationDropdownItem',
    path: '/layout/notification-dropdown-item',
    component: <NotificationDropdownItemExample />,
  },
  {
    title: 'Layout Notification Dropdown Button',
    key: 'layoutNotificationDropdownButton',
    path: '/layout/notification-dropdown-button',
    component: <NotificationDropdownButtonExample />,
  },
  {
    title: 'Layout Notification Dropdown Header',
    key: 'layoutNotificationDropdownHeader',
    path: '/layout/notification-dropdown-header',
    component: <NotificationDropdownHeaderExample />,
  },
  {
    title: 'Layout Notification Dropdown Footer',
    key: 'layoutNotificationDropdownFooter',
    path: '/layout/notification-dropdown-footer',
    component: <NotificationDropdownFooterExample />,
  },
  {
    title: 'Layout Notification Dropdown',
    key: 'layoutNotificationDropdown',
    path: '/layout/notification-dropdown',
    component: <NotificationDropdownExample />,
  },
  {
    title: 'Layout Program Dropdown',
    key: 'layoutProgramDropdown',
    path: '/layout/program-dropdown-example',
    component: <ProgramDropdownExample />,
  },
  {
    title: 'UI Card',
    key: 'uiCard',
    path: '/ui/card',
    component: <CardExample />,
  },
].map((route) => ({
  ...route,
  path: ('/dev' + route.path) as Path,
}))

export default devRoutes
