import React from 'react'
import Dropdown from '../../../../../ui/Dropdown'
import Icon from '../../../../../ui/Icon'
import UserDropdownHeader from './UserDropdownHeader'
import UserDropdownButton from './UserDropdownButton'
import useSignOut from '../../../../../../hooks/useSignOut'
import { UserFieldsFragment } from '../../../../../../graphql'

const IconComponent = <Icon name={'placeholder'} />

export type UserDropdownProps = {
  user?: UserFieldsFragment
}

const UserDropdown = ({ user }: UserDropdownProps) => {
  const signOut = useSignOut()

  return (
    <Dropdown
      placement={'bottom-end'}
      renderHeader={
        <Dropdown.Header>
          <UserDropdownHeader user={user} />
        </Dropdown.Header>
      }
      renderToggle={<UserDropdownButton user={user} />}>
      <Dropdown.Item
        key={'profile-switch'}
        onClick={() => console.log('onClick')}
        title={'Switch to Leader'}
        icon={IconComponent}
        eventKey={`user-dropdown-item-profile-switch`}
      />
      <Dropdown.Item
        key={'account-settings'}
        onClick={() => console.log('onClick')}
        title={'Account Settings'}
        icon={IconComponent}
        eventKey={`user-dropdown-item-account-settings`}
      />
      <Dropdown.Item
        key={'log-out'}
        onClick={() => signOut()}
        title={'Log Out'}
        icon={IconComponent}
        eventKey={`user-dropdown-item-log-out`}
      />
    </Dropdown>
  )
}

export default UserDropdown
