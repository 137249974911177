import React, { FC, PropsWithChildren, useEffect } from 'react'
import { useMetaTags, MetaTagsConfig } from 'react-metatags-hook'

type ProtectedViewProps = {
  meta?: MetaTagsConfig
  title?: string
}

const ProtectedView: FC<PropsWithChildren<ProtectedViewProps>> = ({ meta = {}, title = '', children }) => {
  const programName = 'Program Name' // Todo: Get the selected program's name
  useMetaTags(
    {
      title: `${title} – ${programName}`,
      ...meta,
    },
    [title, meta],
  )

  useEffect(() => {
    document.body.classList.remove('bg-white')
    document.body.classList.add('bg-gray-200')
  }, [])

  return (
    <div
      className={
        'flex-1 overflow-x-scroll bg-gray-200 pt-[40px] pl-[16px] pr-[16px] pb-[16px] sm:pl-[20px] sm:pr-[20px] sm:pb-[20px] md:pl-[40px] md:pr-[40px] md:pb-[40px]'
      }>
      {children}
    </div>
  )
}

export default ProtectedView
