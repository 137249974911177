import React, { FC } from 'react'
import Icon, { IconProps } from '../../../../ui/Icon/index'

type UtilityToggleProps = Omit<IconProps, 'name'>

const UtilityToggle: FC<UtilityToggleProps> = (props) => {
  return <Icon name={'check-square'} {...props} />
}

export default UtilityToggle
