import React, { ChangeEvent, InputHTMLAttributes, MouseEvent, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import Icon from '../Icon'
import { FieldInputProps } from 'formik'

type CheckboxProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'size' | 'onChange'> & {
  label?: string
  checked?: boolean
  disabled?: boolean
  size?: 'sm' | 'md' | 'lg'
  onChange?: (value: boolean, e: ChangeEvent<HTMLInputElement>) => void
  field?: FieldInputProps<unknown>
}

const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  ({ checked = false, label = '', disabled = false, size = 'md', onChange, field, ...rest }: CheckboxProps, ref) => {
    const [value, setValue] = useState(checked)
    const key = `checkbox-${uuidv4()}`

    let onFieldChange = field?.onChange

    const onCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
      onChange?.(value, e)
    }

    const onCheckboxClicked = (e: MouseEvent<HTMLDivElement>) => {
      console.log('click')
      if (disabled) return
      const newValue = !value
      setValue(newValue)
      onFieldChange?.(e)
    }

    const checkedValue = field && 'value' in field ? !!field.value : value

    return (
      <label htmlFor={key} className={`flex justify-center items-center ${disabled ? 'opacity-50' : 'cursor-pointer'}`}>
        <div
          onClick={onCheckboxClicked}
          className={`pointer-events-none relative flex justify-center items-center ${
            !disabled ? 'cursor-pointer' : ''
          }`}>
          <input
            ref={ref}
            id={key}
            type={'checkbox'}
            checked={checkedValue}
            disabled={disabled}
            className={`h-[18px] w-[18px] box-content border-[2px] border-gray-light rounded-[5px] shrink-0 checked:bg-gray-900 checked:border-gray-900 appearance-none transition-all bg-white ${
              !disabled ? 'cursor-pointer' : ''
            }`}
            {...(field as InputHTMLAttributes<HTMLInputElement>)}
            onChange={onCheckboxChange}
            {...rest}
          />
          <div className={'absolute'}>
            <Icon name={'check-bold'} color={'white'} size={15} />
          </div>
        </div>
        <span className={`ml-[8px] ${size === 'sm' ? 'body-sm' : size === 'md' ? 'body-md' : 'body-lg'}`}>{label}</span>
      </label>
    )
  },
)

export default Checkbox
