import React from 'react'
import { Navigate } from 'react-router-dom'
//import useAuthority from '../../../../hooks/useAuthority'
//import useAuth from '../../../../hooks/useAuth'
import { Role } from '../../../../graphql'

type AuthorityGuardProps = {
  authority?: Role[]
  children: React.ReactNode
}
const AuthorityGuard = (props: AuthorityGuardProps) => {
  const { /*authority,*/ children } = props

  //const { user } = useAuth()

  const roleMatched = true // useAuthority(user?.roles, authority)

  return roleMatched ? children : <Navigate to="/access-denied" />
}

export default AuthorityGuard
