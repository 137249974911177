import { SignInDocument, Role, ViewAs } from '../../graphql'
import { GraphQLError } from 'graphql'

const authMocks = [
  {
    request: {
      query: SignInDocument,
      variables: {
        data: {
          email: 'test@test.com',
          password: '1234',
        },
      },
    },
    result: {
      data: {
        login: {
          token: '1234',
          me: {
            id: '1',
            firstName: 'Joe',
            lastName: 'Schmoe',
            email: 'test@test.com',
            roles: [Role.Admin],
            lastViewedAs: ViewAs.Admin,
            lastViewedProgramId: '1',
            avatar: {
              src: 'https://randomuser.me/api/portraits/men/85.jpg',
            },
            __typename: 'Me',
          },
          __typename: 'Auth',
        },
      },
    },
  },
  {
    request: {
      query: SignInDocument,
      variables: {
        data: {
          email: 'invalid@test.com',
          password: '1234',
        },
      },
    },
    result: {
      errors: [
        new GraphQLError('Invalid email or password', null, null, null, null, null, {
          name: 'ForbiddenError',
          code: 'FORBIDDEN',
        }),
      ],
    },
  },
  {
    request: {
      query: SignInDocument,
      variables: {
        data: {
          email: 'error@test.com',
          password: '1234',
        },
      },
    },
    error: new Error('Network error. This message should be hidden from user'),
  },
]

export default authMocks
