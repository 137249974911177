import React, { useRef, useState, useEffect, ReactNode, CSSProperties, useCallback, MouseEvent } from 'react'
import DropdownMenu from './DropdownMenu'
import DropdownToggle from './DropdownToggle'
import DropdownItem from './DropdownItem'
//import DropdownDivider from './DropdownDivider'
import { Divider } from '../index'
import DropdownGroup from './DropdownGroup'
import DropdownHeader from './DropdownHeader'
import classNames from 'classnames'

export type DropdownContextType = {
  activeKey?: string
  onSelect?: (eventKey: string, e: MouseEvent) => void
}
export const DropdownContext = React.createContext<DropdownContextType>({})

export type DropdownProps = Omit<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  | 'title'
  | 'renderToggle'
  | 'renderHeader'
  | 'trigger'
  | 'placement'
  | 'menuClass'
  | 'menuStyle'
  | 'toggleClassName'
  | 'disabled'
  | 'activeKey'
  | 'onClick'
  | 'onMouseEnter'
  | 'onMouseLeave'
  | 'onContextMenu'
  | 'onSelect'
  | 'onOpen'
  | 'onClose'
  | 'onToggle'
  | 'children'
> & {
  title?: string
  renderToggle?: ReactNode
  renderHeader?: ReactNode
  trigger?: 'click' | 'hover' | 'context'
  className?: string
  placement?:
    | 'top-start'
    | 'top-center'
    | 'top-end'
    | 'bottom-start'
    | 'bottom-center'
    | 'bottom-end'
    | 'middle-start-top'
    | 'middle-start-bottom'
    | 'middle-end-top'
    | 'middle-end-bottom'
  menuClass?: string
  menuStyle?: CSSProperties
  toggleClassName?: string
  disabled?: boolean
  activeKey?: string
  onClick?: (e: MouseEvent) => void
  onMouseEnter?: (e: MouseEvent) => void
  onMouseLeave?: (e: MouseEvent) => void
  onContextMenu?: (e: MouseEvent) => void
  onSelect?: (eventKey: string, e: MouseEvent) => void
  onOpen?: () => void
  onClose?: () => void
  onToggle?: () => void
  delayCloseOnSelect?: boolean
  closeDelayDuration?: number
  children?: ReactNode
}

const Dropdown = ({
  title,
  renderToggle,
  renderHeader,
  trigger = 'click',
  placement = 'bottom-start',
  menuClass,
  menuStyle,
  toggleClassName,
  disabled = false,
  activeKey,
  className,
  onClick,
  onMouseEnter,
  onMouseLeave,
  onContextMenu,
  onSelect,
  onOpen,
  onClose,
  onToggle,
  delayCloseOnSelect = false,
  closeDelayDuration = 0,
  children,
}: DropdownProps) => {
  const [open, setOpen] = useState(false)
  const [useSheet, setUseSheet] = useState(false)
  const [isHoveringButton, setIsHoveringButton] = useState(false)
  const [isHoveringDropdown, setIsHoveringDropdown] = useState(false)

  const ref = useRef<HTMLDivElement>(null)

  const onResize = () => {
    if (window.innerWidth < 768) {
      setUseSheet(true)
    } else {
      setUseSheet(false)
    }
  }

  useEffect(() => {
    window.addEventListener('resize', onResize)
    onResize()
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  const handleToggle = useCallback(
    (isOpen: boolean) => {
      onToggle?.()
      if (isOpen) {
        onOpen?.()
        setOpen(isOpen)
        if (!useSheet) {
          setTimeout(() => {
            ref?.current?.classList?.remove('opacity-0')
            ref?.current?.classList?.add('opacity-1')
          }, 50)
        }
      } else if (!isOpen) {
        onClose?.()
        if (!useSheet) {
          ref?.current?.classList?.remove('opacity-1')
          ref?.current?.classList?.add('opacity-0')
        }
        setTimeout(() => {
          setOpen(isOpen)
        }, 150)
      }
    },
    [onClose, onOpen, onToggle, useSheet],
  )

  const handleClick = useCallback(
    (e: MouseEvent) => {
      if ((useSheet && !open) || (!useSheet && !disabled && trigger === 'click')) {
        handleToggle(!open)
        onClick?.(e)
      }
    },
    [disabled, handleToggle, onClick, open, trigger, useSheet],
  )

  useEffect(() => {
    if (!disabled && trigger === 'hover') {
      if (isHoveringButton || isHoveringDropdown) {
        handleToggle(true)
      } else {
        handleToggle(false)
      }
    }
  }, [disabled, trigger, isHoveringDropdown, isHoveringButton, handleToggle])

  const handleContextMenu = useCallback(
    (e: MouseEvent) => {
      if (!disabled && trigger === 'context') {
        handleToggle(!open)
        onContextMenu?.(e)
      }
    },
    [disabled, handleToggle, onContextMenu, open, trigger],
  )

  const handleSelect = (eventKey: string, e: MouseEvent) => {
    onSelect?.(eventKey, e)
    if (delayCloseOnSelect && !useSheet) {
      setTimeout(() => {
        handleToggle(false)
      }, closeDelayDuration)
    } else {
      handleToggle(false)
    }
  }

  useEffect(() => {
    const handleCloseMenu = (e: globalThis.MouseEvent) => {
      e.stopPropagation()
      if (open && !ref?.current?.parentNode?.contains(e.target as Node) && !useSheet) {
        handleToggle(false)
      }
    }
    document.addEventListener('mouseup', handleCloseMenu)
    return () => {
      document.removeEventListener('mouseup', handleCloseMenu)
    }
  }, [handleToggle, open, trigger, useSheet])

  return (
    <DropdownContext.Provider value={{ onSelect: handleSelect, activeKey }}>
      <div
        onMouseEnter={(e: MouseEvent) => {
          if (!disabled && trigger === 'hover') {
            setIsHoveringButton(true)
            onMouseEnter?.(e)
          }
        }}
        onMouseLeave={(e: MouseEvent) => {
          if (!disabled && trigger === 'hover') {
            setTimeout(() => {
              setIsHoveringButton(false)
              onMouseLeave?.(e)
            }, 250)
          }
        }}
        onClick={trigger === 'context' ? handleContextMenu : handleClick}
        className={classNames(
          `relative w-fit flex ${
            placement === 'top-center' || placement === 'bottom-center' ? 'items-center justify-center' : ''
          }`,
          className,
        )}>
        <DropdownToggle
          toggleClassName={toggleClassName}
          renderToggle={renderToggle}
          disabled={disabled}
          placement={placement}
          selected={open}
          title={title}
        />
        <DropdownMenu
          useSheet={useSheet}
          open={open}
          onClose={() => {
            onClose?.()
            setOpen(false)
          }}
          menuClass={menuClass}
          menuStyle={menuStyle}
          onMouseEnter={(e: MouseEvent) => {
            if (!disabled && trigger === 'hover') {
              setIsHoveringDropdown(true)
              onMouseEnter?.(e)
            }
          }}
          onMouseLeave={(e: MouseEvent) => {
            if (!disabled && trigger === 'hover') {
              setTimeout(() => {
                setIsHoveringDropdown(false)
                onMouseLeave?.(e)
              }, 250)
            }
          }}
          ref={ref}
          hidden={!open && !useSheet}
          renderHeader={renderHeader}
          placement={placement}>
          {children}
        </DropdownMenu>
      </div>
    </DropdownContext.Provider>
  )
}

export default Dropdown as typeof Dropdown & {
  Item: typeof DropdownItem
  Menu: typeof DropdownMenu
  Divider: typeof Divider
  Group: typeof DropdownGroup
  Header: typeof DropdownHeader
}
