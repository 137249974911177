import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never }
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never }
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  /**
   * The `BigInt` scalar type represents non-fractional signed whole numeric values.
   * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/BigInt
   */
  BigInt: { input: any; output: any }
  /** The `Byte` scalar type represents byte value as a Buffer */
  Bytes: { input: any; output: any }
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: { input: any; output: any }
  /** An arbitrary-precision Decimal type */
  Decimal: { input: any; output: any }
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  Json: { input: any; output: any }
}

/** The type returned when an invitation fails to be accepted */
export type AcceptInvitationError = MutationResponse & {
  __typename?: 'AcceptInvitationError'
  message: Scalars['String']['output']
}

/** The type returned when a user accepts an invitation */
export type AcceptInvitationResponse = AcceptInvitationError | AcceptInvitationSuccess

/** The type returned when an invitation is successfully accepted */
export type AcceptInvitationSuccess = MutationResponse & {
  __typename?: 'AcceptInvitationSuccess'
  message: Scalars['String']['output']
}

export type Auth = ConfirmationAuth | UserAuth

export type ClientCredential = Record & {
  __typename?: 'ClientCredential'
  createdAt: Scalars['DateTime']['output']
  expiresAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type ClientCredentialList = {
  __typename?: 'ClientCredentialList'
  hasMore: Scalars['Boolean']['output']
  list: Array<ClientCredential>
  total: Scalars['Int']['output']
}

export type ClientCredentialWhereInput = {
  id?: InputMaybe<StringFilter>
}

/** A type that relates an email or mobile number confirmation to a client credential */
export type Confirmation = {
  __typename?: 'Confirmation'
  /** The client credential details for the sent confirmation code */
  credential: ClientCredential
  /** The intended purpose or reason of the confirmation (sign-in, sign-up, or reset-password) */
  purpose: ConfirmationPurpose
  /** The type of confirmation (email or mobile) */
  type: ConfirmationType
  /** The email or mobile number being confirmed */
  value: Scalars['String']['output']
}

export type ConfirmationAuth = {
  __typename?: 'ConfirmationAuth'
  /** Details about the email or mobile number that was confirmed */
  confirmation: Confirmation
  /** The confirmation token which can be used to authorize the user with the confirmed source, such as signing up with a confirmed email address or signing in with 2FA via a confirmed mobile number */
  token: Scalars['String']['output']
}

/** A type that represents the purpose of a confirmation */
export enum ConfirmationPurpose {
  ResetPassword = 'RESET_PASSWORD',
  SignIn = 'SIGN_IN',
  SignUp = 'SIGN_UP',
}

/** A type that represents a confirmation of an email or mobile number */
export enum ConfirmationType {
  Email = 'EMAIL',
  Mobile = 'MOBILE',
}

/** A type that represents a person's contact information */
export type Contact = Record & {
  __typename?: 'Contact'
  createdAt: Scalars['DateTime']['output']
  /** The email address of the contact */
  email?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  /** The name of the contact */
  name?: Maybe<Scalars['String']['output']>
  /** The phone number of the contact */
  phone?: Maybe<Scalars['String']['output']>
  updatedAt: Scalars['DateTime']['output']
}

export type CreateProgramError = MutationResponse & {
  __typename?: 'CreateProgramError'
  message: Scalars['String']['output']
}

export type CreateProgramInput = {
  /** The name of the program */
  name: Scalars['String']['input']
  /** The process template ID of the program which will be used to create the default process */
  processTemplateId: Scalars['String']['input']
  /** The tenant ID of the program */
  tenantId: Scalars['String']['input']
}

export type CreateProgramResponse = CreateProgramError | CreateProgramSuccess

export type CreateProgramSuccess = MutationResponse & {
  __typename?: 'CreateProgramSuccess'
  message: Scalars['String']['output']
  program: Program
}

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']['input']>
  gt?: InputMaybe<Scalars['DateTime']['input']>
  gte?: InputMaybe<Scalars['DateTime']['input']>
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  lt?: InputMaybe<Scalars['DateTime']['input']>
  lte?: InputMaybe<Scalars['DateTime']['input']>
  not?: InputMaybe<DateTimeFilter>
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
}

/** The type returned when an invitation fails to be declined */
export type DeclineInvitationError = MutationResponse & {
  __typename?: 'DeclineInvitationError'
  message: Scalars['String']['output']
}

/** The type returned when a user declines an invitation */
export type DeclineInvitationResponse = DeclineInvitationError | DeclineInvitationSuccess

/** The type returned when an invitation is successfully declined */
export type DeclineInvitationSuccess = MutationResponse & {
  __typename?: 'DeclineInvitationSuccess'
  message: Scalars['String']['output']
}

/** A type that represents a file size format */
export type Format = Record & {
  __typename?: 'Format'
  createdAt: Scalars['DateTime']['output']
  /** The hash of the formatted file */
  hash: Scalars['String']['output']
  /** The height of the formatted file */
  height: Scalars['Int']['output']
  id: Scalars['ID']['output']
  /** The mime type of the formatted file */
  mime: Scalars['String']['output']
  /** The name of the formatted file */
  name: Scalars['String']['output']
  /** The path to the formatted file */
  path: Scalars['String']['output']
  /** The size of the formatted file in bytes */
  size: Scalars['Int']['output']
  updatedAt: Scalars['DateTime']['output']
  /** The url of the formatted file */
  url: Scalars['String']['output']
  /** The width of the formatted file */
  width: Scalars['Int']['output']
}

/** A type that represents a file's different format sizes */
export type Formats = Record & {
  __typename?: 'Formats'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  /** The large format of the file */
  large?: Maybe<Format>
  /** The medium format of the file */
  medium?: Maybe<Format>
  /** The small format of the file */
  small?: Maybe<Format>
  /** The thumbnail format of the file */
  thumbnail?: Maybe<Format>
  updatedAt: Scalars['DateTime']['output']
}

/** An image */
export type Image = {
  __typename?: 'Image'
  /** The src url of the image */
  src: Scalars['String']['output']
}

/** A type that represents an invitation to join an organization */
export type Invitation = Record & {
  __typename?: 'Invitation'
  createdAt: Scalars['DateTime']['output']
  /** The email address of the person being invited */
  email: Scalars['String']['output']
  id: Scalars['ID']['output']
  /** The date the invitation was last sent */
  lastSentAt: Scalars['DateTime']['output']
  /** A peronal message by the sender to the person being invited */
  message?: Maybe<Scalars['String']['output']>
  /** The program the person is being invited to (only for GUEST invitations) */
  program?: Maybe<Program>
  sender: Person
  /** The organization the person is being invited to (only for >= MEMBER invitations) */
  tenant?: Maybe<Tenant>
  type: InvitationType
  updatedAt: Scalars['DateTime']['output']
}

export enum InvitationType {
  Guest = 'GUEST',
  Member = 'MEMBER',
}

export type InvitationWhereInput = {
  AND?: InputMaybe<Array<InvitationWhereInput>>
  NOT?: InputMaybe<Array<InvitationWhereInput>>
  OR?: InputMaybe<Array<InvitationWhereInput>>
  id?: InputMaybe<StringFilter>
  lastSentAt?: InputMaybe<DateTimeFilter>
  message?: InputMaybe<StringFilter>
  program?: InputMaybe<ProgramWhereInput>
  tenant?: InputMaybe<TenantWhereInput>
}

export type InvitationWhereUniqueInput = {
  id: Scalars['String']['input']
}

export type Invitations = {
  __typename?: 'Invitations'
  hasMore: Scalars['Boolean']['output']
  list: Array<Invitation>
  total: Scalars['Int']['output']
}

/** The type returned when a admin or owner user fails to join a program */
export type JoinProgramError = MutationResponse & {
  __typename?: 'JoinProgramError'
  message: Scalars['String']['output']
}

/** The type returned when a admin or owner user joins a program */
export type JoinProgramResponse = JoinProgramError | JoinProgramSuccess

/** The type returned when a admin or owner user successfully joins a program */
export type JoinProgramSuccess = MutationResponse & {
  __typename?: 'JoinProgramSuccess'
  message: Scalars['String']['output']
}

/** A type that represents a journey */
export type Journey = Record & {
  __typename?: 'Journey'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  /** The name of the journey */
  name: Scalars['String']['output']
  /** The program the journey is under */
  program: Program
  /** The tenant organization the journey belongs to */
  tenant: Tenant
  updatedAt: Scalars['DateTime']['output']
}

/** The type returned when a user successfully signs out */
export type LogoutSuccess = MutationResponse & {
  __typename?: 'LogoutSuccess'
  message: Scalars['String']['output']
}

/** A type that represents a file */
export type Media = Record & {
  __typename?: 'Media'
  /** The alternative text for the file */
  alternativeText?: Maybe<Scalars['String']['output']>
  /** The caption for the file */
  caption?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['DateTime']['output']
  /** The file extension */
  ext: Scalars['String']['output']
  /** The folder the file is in */
  folder: Scalars['String']['output']
  /** The path to the folder the file is in */
  folderPath: Scalars['String']['output']
  /** The different file formats */
  formats: Formats
  /** The hash of the file */
  hash: Scalars['String']['output']
  /** The height of the file */
  height?: Maybe<Scalars['Int']['output']>
  id: Scalars['ID']['output']
  /** The mime type of the file */
  mime: Scalars['String']['output']
  /** The name of the file */
  name: Scalars['String']['output']
  /** The preview url of the file */
  previewUrl?: Maybe<Scalars['String']['output']>
  /** The file provider */
  provider?: Maybe<Scalars['String']['output']>
  /** The file provider metadata */
  providerMetadata?: Maybe<Scalars['Json']['output']>
  /** The size of the file in bytes */
  size: Scalars['Int']['output']
  /** The tenant organization the file belongs to */
  tenant?: Maybe<Tenant>
  updatedAt: Scalars['DateTime']['output']
  /** The url of the file */
  url: Scalars['String']['output']
  /** The width of the file */
  width?: Maybe<Scalars['Int']['output']>
}

export type Mutation = {
  __typename?: 'Mutation'
  /** Accept a member invitation to a tenant or a guest invitation to a program */
  acceptInvitation?: Maybe<AcceptInvitationResponse>
  createProgram: CreateProgramResponse
  /** Decline a member invitation to a tenant or a guest invitation to a program */
  declineInvitation?: Maybe<DeclineInvitationResponse>
  /** Join a program as an admin or owner of a tenant */
  joinProgram: JoinProgramResponse
  /** Confirm a code sent to an email address or mobile number, or a token provided in a confirmation link */
  receiveConfirmation: ReceiveConfirmationResponse
  /** Register a new tenant */
  registerTenant: RegisterTenantResponse
  /** Send a confirmation code/link to an email address or mobile number */
  sendConfirmation: SendConfirmationResponse
  /** Sign into the system */
  signIn: SignInResponse
  /** Sign out of the system by deleting the login session's client credentials */
  signOut: SignOutResponse
  /** Signup by creating a new user */
  signup: SignupResponse
  upsertNotifications: Array<Notification>
}

export type MutationAcceptInvitationArgs = {
  where: InvitationWhereUniqueInput
}

export type MutationCreateProgramArgs = {
  data: CreateProgramInput
}

export type MutationDeclineInvitationArgs = {
  where: InvitationWhereUniqueInput
}

export type MutationJoinProgramArgs = {
  where: ProgramWhereUniqueInput
}

export type MutationReceiveConfirmationArgs = {
  data: ReceiveConfirmationInput
}

export type MutationRegisterTenantArgs = {
  data: RegisterTenantInput
}

export type MutationSendConfirmationArgs = {
  data: SendConfirmationInput
}

export type MutationSignInArgs = {
  data?: InputMaybe<SignInInput>
}

export type MutationSignupArgs = {
  data: SignupInput
}

export type MutationUpsertNotificationsArgs = {
  data: UpsertNotificationsInput
}

export type MutationResponse = {
  message: Scalars['String']['output']
}

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']['input']>
  endsWith?: InputMaybe<Scalars['String']['input']>
  equals?: InputMaybe<Scalars['String']['input']>
  gt?: InputMaybe<Scalars['String']['input']>
  gte?: InputMaybe<Scalars['String']['input']>
  in?: InputMaybe<Array<Scalars['String']['input']>>
  lt?: InputMaybe<Scalars['String']['input']>
  lte?: InputMaybe<Scalars['String']['input']>
  not?: InputMaybe<NestedStringFilter>
  notIn?: InputMaybe<Array<Scalars['String']['input']>>
  startsWith?: InputMaybe<Scalars['String']['input']>
}

/** A type that represents a notification to a recipient user */
export type Notification = Record & {
  __typename?: 'Notification'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  /** The sender user of the notification */
  person?: Maybe<Person>
  /** The clickable prefix of the notification */
  prefix?: Maybe<Scalars['String']['output']>
  /** The recipient user of the notification */
  recipient: User
  /** The main text of the notification */
  text?: Maybe<Scalars['String']['output']>
  /** An optional HTML formatted text to be rendered instead of the main text */
  textHtml?: Maybe<Scalars['String']['output']>
  /** The type of notification */
  type: NotificationType
  updatedAt: Scalars['DateTime']['output']
  /** Whether or not the recipient has viewed the notification */
  viewed: Scalars['Boolean']['output']
  /** The date and time the recipient viewed the notification */
  viewedOn?: Maybe<Scalars['DateTime']['output']>
}

export type NotificationInput = {
  programId: Scalars['String']['input']
  viewingAs: ViewAs
}

export enum NotificationType {
  Error = 'ERROR',
  Info = 'INFO',
  Success = 'SUCCESS',
  Warning = 'WARNING',
}

export enum OnboardingFlow {
  Organizational = 'ORGANIZATIONAL',
  Personal = 'PERSONAL',
}

/** A type that represents a person */
export type Person = Record & {
  __typename?: 'Person'
  /** The additional contact information for the person */
  contacts: Array<Contact>
  createdAt: Scalars['DateTime']['output']
  /** The email address of the person corresponding to their account */
  email?: Maybe<Scalars['String']['output']>
  /** The first name of the person */
  firstName: Scalars['String']['output']
  id: Scalars['ID']['output']
  /** The last name of the person */
  lastName?: Maybe<Scalars['String']['output']>
  /** The middle name of the person */
  middleName?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  /** The primary contact information for the person */
  primaryContact?: Maybe<Contact>
  /** The profile image of the person */
  profileImage?: Maybe<Media>
  updatedAt: Scalars['DateTime']['output']
}

export type Phase = Record & {
  __typename?: 'Phase'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  /** The name of the phase */
  name: Scalars['String']['output']
  /** The process the phase is under */
  process: Process
  /** The related state */
  state: State
  /** The template this phase derived from */
  template?: Maybe<PhaseTemplate>
  updatedAt: Scalars['DateTime']['output']
}

/** A type that represents a phase template which defines the state */
export type PhaseTemplate = Record & {
  __typename?: 'PhaseTemplate'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  /** The name of the phase */
  name: Scalars['String']['output']
  /** The phases that implement this phase template */
  phases: Array<Phase>
  /** The process template the phase template is under */
  process: ProcessTemplate
  /** The state template related to this phase template */
  state: StateTemplate
  updatedAt: Scalars['DateTime']['output']
}

export type Process = Record & {
  __typename?: 'Process'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  /** The name of the process */
  name: Scalars['String']['output']
  /** The phases belonging to this process */
  phases: Array<Phase>
  /** The programs that implement this process */
  programs: Array<Program>
  /** The template for this process that defines the phases and states */
  template?: Maybe<ProcessTemplate>
  /** The tenant organization that owns the process */
  tenant: Tenant
  updatedAt: Scalars['DateTime']['output']
}

export type ProcessTemplate = Record & {
  __typename?: 'ProcessTemplate'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  /** The name of the process */
  name: Scalars['String']['output']
  /** The phases belonging to this process template */
  phases: Array<PhaseTemplate>
  /** The processes that implement this process template */
  processes: Array<Process>
  /** The type of process which ties to its use case */
  type: ProcessType
  updatedAt: Scalars['DateTime']['output']
}

export type ProcessTemplateWhereInput = {
  AND?: InputMaybe<Array<ProcessTemplateWhereInput>>
  NOT?: InputMaybe<Array<ProcessTemplateWhereInput>>
  OR?: InputMaybe<Array<ProcessTemplateWhereInput>>
  id?: InputMaybe<StringFilter>
  name?: InputMaybe<StringFilter>
  tenant?: InputMaybe<TenantWhereInput>
  type?: InputMaybe<ProcessTypeFilter>
}

export type ProcessTemplates = {
  __typename?: 'ProcessTemplates'
  hasMore: Scalars['Boolean']['output']
  list: Array<ProcessTemplate>
  total: Scalars['Int']['output']
}

export enum ProcessType {
  Custom = 'CUSTOM',
  Dtms = 'DTMS',
  Gtms = 'GTMS',
  Rtms = 'RTMS',
}

export type ProcessTypeFilter = {
  equals?: InputMaybe<ProcessType>
  in?: InputMaybe<Array<ProcessType>>
  not?: InputMaybe<ProcessTypeFilter>
  notIn?: InputMaybe<Array<ProcessType>>
}

/** A type that represents a program */
export type Program = Record & {
  __typename?: 'Program'
  createdAt: Scalars['DateTime']['output']
  icon?: Maybe<Image>
  id: Scalars['ID']['output']
  /** The image for the program */
  image?: Maybe<Media>
  /** The journeys under the program */
  journeys: Array<Journey>
  /** The name of the program */
  name: Scalars['String']['output']
  /** The process implemented by the program */
  process: Process
  /** The tenant organization the program belongs to */
  tenant: Tenant
  updatedAt: Scalars['DateTime']['output']
  /** The users that belong to the program */
  users: Array<ProgramUser>
}

/** A type that represents users who belong to a program and their roles */
export type ProgramUser = Record & {
  __typename?: 'ProgramUser'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  program: Program
  roles: Array<ProgramUserRole>
  updatedAt: Scalars['DateTime']['output']
  user: User
}

/** The role a program guest can assume */
export enum ProgramUserRole {
  Admin = 'ADMIN',
  Apprentice = 'APPRENTICE',
  Leader = 'LEADER',
}

export type ProgramWhereInput = {
  AND?: InputMaybe<Array<ProgramWhereInput>>
  NOT?: InputMaybe<Array<ProgramWhereInput>>
  OR?: InputMaybe<Array<ProgramWhereInput>>
  id?: InputMaybe<StringFilter>
  name?: InputMaybe<StringFilter>
  tenant?: InputMaybe<TenantWhereInput>
}

export type ProgramWhereUniqueInput = {
  id: Scalars['String']['input']
}

export type Programs = {
  __typename?: 'Programs'
  hasMore: Scalars['Boolean']['output']
  list: Array<Program>
  total: Scalars['Int']['output']
}

export type Query = {
  __typename?: 'Query'
  invitation: Invitation
  invitations: Invitations
  /** Get the currently authenticated user */
  me?: Maybe<User>
  notifications: Array<Notification>
  processTemplates: ProcessTemplates
  programs: Programs
  slides: Slides
}

export type QueryInvitationArgs = {
  where: InvitationWhereUniqueInput
}

export type QueryInvitationsArgs = {
  type?: InputMaybe<InvitationType>
  where?: InputMaybe<InvitationWhereInput>
}

export type QueryNotificationsArgs = {
  data: NotificationInput
}

export type QueryProcessTemplatesArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>
  take?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ProcessTemplateWhereInput>
}

export type QueryProgramsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>
  take?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ProgramWhereInput>
}

export type QuerySlidesArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>
  take?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<SlideWhereInput>
}

/** The type returned when a confirmation fails to be received */
export type ReceiveConfirmationError = MutationResponse & {
  __typename?: 'ReceiveConfirmationError'
  message: Scalars['String']['output']
}

/** The input type for confirming an email or mobile number */
export type ReceiveConfirmationInput = {
  /** The client ID of the confirmation code (required if authorization header is not provided) */
  clientId?: InputMaybe<Scalars['String']['input']>
  /** The confirmation code (required if authorization header is not provided) */
  code?: InputMaybe<Scalars['String']['input']>
}

/** The type returned when a user attempts to confirm an email or mobile number */
export type ReceiveConfirmationResponse = ReceiveConfirmationError | ReceiveConfirmationSuccess

/** The type returned when a confirmation is successfully received */
export type ReceiveConfirmationSuccess = MutationResponse & {
  __typename?: 'ReceiveConfirmationSuccess'
  auth: Auth
  message: Scalars['String']['output']
}

export type Record = {
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type RecordByIdInput = {
  id: Scalars['String']['input']
}

export type RecordsByIdsInput = {
  ids: Array<Scalars['String']['input']>
}

export type RegisterTenantCreateProgramInput = {
  /** The name of the program */
  name: Scalars['String']['input']
  /** The process template ID of the program which will be used to create the default process */
  processTemplateId: Scalars['String']['input']
}

/** The type returned when a tenant fails to register */
export type RegisterTenantError = MutationResponse & {
  __typename?: 'RegisterTenantError'
  message: Scalars['String']['output']
}

/** The input type for registering a new tenant */
export type RegisterTenantInput = {
  /** The name of the tenant */
  name: Scalars['String']['input']
  programs?: InputMaybe<RegisterTenantProgramsInput>
  type: TenantType
}

export type RegisterTenantProgramsInput = {
  create: RegisterTenantCreateProgramInput
}

/** The type returned when a tenant registers */
export type RegisterTenantResponse = RegisterTenantError | RegisterTenantSuccess

/** The type returned when a tenant successfully registers */
export type RegisterTenantSuccess = MutationResponse & {
  __typename?: 'RegisterTenantSuccess'
  message: Scalars['String']['output']
  /** The tenant that registered */
  tenant: Tenant
}

export enum Role {
  Admin = 'admin',
  Apprentice = 'apprentice',
  Leader = 'leader',
  Owner = 'owner',
}

/** The type returned when a confirmation fails to be created or sent */
export type SendConfirmationError = MutationResponse & {
  __typename?: 'SendConfirmationError'
  message: Scalars['String']['output']
}

/** The input type for sending an email or mobile number confirmation */
export type SendConfirmationInput = {
  /** The intended purpose or reason for the confirmation and how the received code should therefore be used */
  purpose: ConfirmationPurpose
  /** The type of confirmation to send */
  type: ConfirmationType
  /** The email address or mobile number to be confirmed */
  value: Scalars['String']['input']
}

/** The type returned when a user attempts to send an email or mobile number confirmation */
export type SendConfirmationResponse = SendConfirmationError | SendConfirmationSuccess

/** The type returned when a confirmation code is successfully sent */
export type SendConfirmationSuccess = MutationResponse & {
  __typename?: 'SendConfirmationSuccess'
  /** Details about the confirmation that was sent */
  confirmation: Confirmation
  message: Scalars['String']['output']
}

/** The type returned when a user fails to sign in */
export type SignInError = MutationResponse & {
  __typename?: 'SignInError'
  message: Scalars['String']['output']
}

/** The input type for the sign in mutation */
export type SignInInput = {
  /** The email address of the user */
  email: Scalars['String']['input']
  /** The password of the user */
  password: Scalars['String']['input']
}

/** The type returned when a user signs in */
export type SignInResponse = SignInError | SignInSuccess

/** The type returned when a user successfully signs in */
export type SignInSuccess = MutationResponse & {
  __typename?: 'SignInSuccess'
  auth: UserAuth
  message: Scalars['String']['output']
}

/** The type returned when a user fails to sign out */
export type SignOutError = MutationResponse & {
  __typename?: 'SignOutError'
  message: Scalars['String']['output']
}

/** The type returned when a user signs out */
export type SignOutResponse = LogoutSuccess | SignOutError

/** The type returned when a user fails to sign up */
export type SignupError = MutationResponse & {
  __typename?: 'SignupError'
  message: Scalars['String']['output']
}

/** The input type for the signup mutation */
export type SignupInput = {
  /** The email address of the user which must match the confirmation token in the authorization header */
  email: Scalars['String']['input']
  /** The name of the user */
  name: Scalars['String']['input']
  /** The password of the user */
  password: Scalars['String']['input']
  /** Whether or not the user wants to subscribe to marketing communications */
  subscribe?: InputMaybe<Scalars['Boolean']['input']>
}

/** The type returned when a user signs up */
export type SignupResponse = SignupError | SignupSuccess

/** The type returned when a user successfully signs up */
export type SignupSuccess = MutationResponse & {
  __typename?: 'SignupSuccess'
  auth: UserAuth
  message: Scalars['String']['output']
}

export type Slide = Record & {
  __typename?: 'Slide'
  createdAt: Scalars['DateTime']['output']
  /** Additional slide data */
  data?: Maybe<Scalars['Json']['output']>
  id: Scalars['ID']['output']
  /** The image used by the slide */
  image?: Maybe<Media>
  /** The slider the slide belongs to */
  slider: Slider
  /** The subtitle of the slide */
  subtitle?: Maybe<Scalars['String']['output']>
  /** The main text content of the slide */
  text?: Maybe<Scalars['String']['output']>
  /** The title of the slide */
  title: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type SlideWhereInput = {
  slider?: InputMaybe<SliderFilter>
}

/** A type that represents the slider a TMS marketing slide goes within */
export enum Slider {
  Auth = 'AUTH',
}

export type SliderFilter = {
  equals?: InputMaybe<Slider>
  in?: InputMaybe<Array<Slider>>
  not?: InputMaybe<SliderFilter>
  notIn?: InputMaybe<Array<Slider>>
}

export type Slides = {
  __typename?: 'Slides'
  hasMore: Scalars['Boolean']['output']
  list: Array<Slide>
  total: Scalars['Int']['output']
}

/** A type that represents a phase's state */
export type State = Record & {
  __typename?: 'State'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  /** The name of the state */
  name: Scalars['String']['output']
  /** The related phase */
  phase?: Maybe<Phase>
  /** The template this state derived from */
  template?: Maybe<StateTemplate>
  updatedAt: Scalars['DateTime']['output']
}

/** A type that represents a state template which defines the state of a phase */
export type StateTemplate = Record & {
  __typename?: 'StateTemplate'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  /** The name of the state */
  name: Scalars['String']['output']
  /** The related phase template */
  phase?: Maybe<PhaseTemplate>
  /** The states that implement this state template */
  states: Array<State>
  updatedAt: Scalars['DateTime']['output']
}

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']['input']>
  endsWith?: InputMaybe<Scalars['String']['input']>
  equals?: InputMaybe<Scalars['String']['input']>
  gt?: InputMaybe<Scalars['String']['input']>
  gte?: InputMaybe<Scalars['String']['input']>
  in?: InputMaybe<Array<Scalars['String']['input']>>
  lt?: InputMaybe<Scalars['String']['input']>
  lte?: InputMaybe<Scalars['String']['input']>
  mode?: InputMaybe<StringFilterQueryMode>
  not?: InputMaybe<NestedStringFilter>
  notIn?: InputMaybe<Array<Scalars['String']['input']>>
  startsWith?: InputMaybe<Scalars['String']['input']>
}

export enum StringFilterQueryMode {
  Default = 'default',
  Insensitive = 'insensitive',
}

export type Tenant = Record & {
  __typename?: 'Tenant'
  createdAt: Scalars['DateTime']['output']
  guests: Array<TenantGuest>
  id: Scalars['ID']['output']
  members: Array<TenantMember>
  /** The name of the tenant */
  name: Scalars['String']['output']
  /** The programs under the tenant */
  programs: Array<Program>
  /** The status of the tenant */
  status: TenantStatus
  /** The process templates under the tenant */
  templates: Array<ProcessTemplate>
  updatedAt: Scalars['DateTime']['output']
}

/** A user that is a guest of a tenant */
export type TenantGuest = Record & {
  __typename?: 'TenantGuest'
  createdAt: Scalars['DateTime']['output']
  /** The email address of the user */
  email: Scalars['String']['output']
  id: Scalars['ID']['output']
  /** The person associated with the user */
  person: Person
  updatedAt: Scalars['DateTime']['output']
}

/** A user that is a member of a tenant */
export type TenantMember = Record & {
  __typename?: 'TenantMember'
  createdAt: Scalars['DateTime']['output']
  /** The email address of the user */
  email: Scalars['String']['output']
  id: Scalars['ID']['output']
  /** The person associated with the user */
  person: Person
  role: TenantMemberRole
  updatedAt: Scalars['DateTime']['output']
}

export enum TenantMemberRole {
  Admin = 'ADMIN',
  Member = 'MEMBER',
  Owner = 'OWNER',
}

export enum TenantStatus {
  Active = 'ACTIVE',
  Intactive = 'INTACTIVE',
  Trial = 'TRIAL',
}

export enum TenantType {
  Organization = 'ORGANIZATION',
  Person = 'PERSON',
}

/** A type that represents users of an organization, their role, and the programs they belong to */
export type TenantUser = Record & {
  __typename?: 'TenantUser'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  programs: Array<ProgramUser>
  role: TenantUserRole
  tenant: Tenant
  updatedAt: Scalars['DateTime']['output']
  user: User
}

/** The role an organization member can assume */
export enum TenantUserRole {
  Admin = 'ADMIN',
  Guest = 'GUEST',
  Member = 'MEMBER',
  Owner = 'OWNER',
}

export type TenantWhereInput = {
  id?: InputMaybe<StringFilter>
  name?: InputMaybe<StringFilter>
}

export type UpsertNotificationInput = {
  id: Scalars['String']['input']
  viewed: Scalars['Boolean']['input']
}

export type UpsertNotificationsInput = {
  notifications: Array<UpsertNotificationInput>
  programId: Scalars['String']['input']
  viewingAs: ViewAs
}

/** A type that represents a user */
export type User = Record & {
  __typename?: 'User'
  createdAt: Scalars['DateTime']['output']
  /** The email address of the user */
  email: Scalars['String']['output']
  id: Scalars['ID']['output']
  lastViewedProgramId?: Maybe<Scalars['String']['output']>
  onboardingFlow?: Maybe<OnboardingFlow>
  /** The person associated with the user */
  person: Person
  programs: Array<ProgramUser>
  /** The tenant organizations the user is a part of */
  tenants: Array<TenantUser>
  updatedAt: Scalars['DateTime']['output']
  viewingAs?: Maybe<ViewAs>
  viewingProgramId?: Maybe<Scalars['String']['output']>
}

export type UserAuth = {
  __typename?: 'UserAuth'
  /** The login token for the user */
  token: Scalars['String']['output']
  /** The user that logged in */
  user: User
}

export enum ViewAs {
  Admin = 'admin',
  Apprentice = 'apprentice',
  Leader = 'leader',
}

export type PersonFieldsFragment = { __typename?: 'Person'; id: string; firstName: string; lastName?: string | null }

export type UserFieldsFragment = {
  __typename?: 'User'
  id: string
  viewingProgramId?: string | null
  viewingAs?: ViewAs | null
  onboardingFlow?: OnboardingFlow | null
  lastViewedProgramId?: string | null
  email: string
  person: {
    __typename?: 'Person'
    id: string
    firstName: string
    lastName?: string | null
    name: string
    email?: string | null
    profileImage?: { __typename?: 'Media'; url: string } | null
  }
  tenants: Array<{
    __typename?: 'TenantUser'
    role: TenantUserRole
    tenant: { __typename?: 'Tenant'; id: string; name: string }
  }>
  programs: Array<{
    __typename?: 'ProgramUser'
    program: {
      __typename?: 'Program'
      id: string
      name: string
      process: {
        __typename?: 'Process'
        name: string
        template?: { __typename?: 'ProcessTemplate'; name: string; type: ProcessType } | null
      }
      tenant: { __typename?: 'Tenant'; id: string; name: string }
    }
  }>
}

export type ConfirmationFieldsFragment = {
  __typename?: 'Confirmation'
  type: ConfirmationType
  value: string
  purpose: ConfirmationPurpose
  credential: { __typename?: 'ClientCredential'; id: string }
}

export type ConfirmationAuthFieldsFragment = {
  __typename?: 'ConfirmationAuth'
  token: string
  confirmation: { __typename?: 'Confirmation'; type: ConfirmationType; value: string }
}

export type UserAuthFieldsFragment = {
  __typename?: 'UserAuth'
  token: string
  user: {
    __typename?: 'User'
    id: string
    email: string
    viewingProgramId?: string | null
    viewingAs?: ViewAs | null
    onboardingFlow?: OnboardingFlow | null
    lastViewedProgramId?: string | null
    person: { __typename?: 'Person'; id: string; firstName: string; lastName?: string | null; name: string }
    tenants: Array<{
      __typename?: 'TenantUser'
      role: TenantUserRole
      tenant: { __typename?: 'Tenant'; id: string; name: string }
    }>
    programs: Array<{
      __typename?: 'ProgramUser'
      program: {
        __typename?: 'Program'
        id: string
        name: string
        process: {
          __typename?: 'Process'
          name: string
          template?: { __typename?: 'ProcessTemplate'; name: string; type: ProcessType } | null
        }
        tenant: { __typename?: 'Tenant'; id: string; name: string }
      }
    }>
  }
}

type AuthFields_ConfirmationAuth_Fragment = {
  __typename?: 'ConfirmationAuth'
  token: string
  confirmation: { __typename?: 'Confirmation'; type: ConfirmationType; value: string }
}

type AuthFields_UserAuth_Fragment = {
  __typename?: 'UserAuth'
  token: string
  user: {
    __typename?: 'User'
    id: string
    email: string
    viewingProgramId?: string | null
    viewingAs?: ViewAs | null
    onboardingFlow?: OnboardingFlow | null
    lastViewedProgramId?: string | null
    person: { __typename?: 'Person'; id: string; firstName: string; lastName?: string | null; name: string }
    tenants: Array<{
      __typename?: 'TenantUser'
      role: TenantUserRole
      tenant: { __typename?: 'Tenant'; id: string; name: string }
    }>
    programs: Array<{
      __typename?: 'ProgramUser'
      program: {
        __typename?: 'Program'
        id: string
        name: string
        process: {
          __typename?: 'Process'
          name: string
          template?: { __typename?: 'ProcessTemplate'; name: string; type: ProcessType } | null
        }
        tenant: { __typename?: 'Tenant'; id: string; name: string }
      }
    }>
  }
}

export type AuthFieldsFragment = AuthFields_ConfirmationAuth_Fragment | AuthFields_UserAuth_Fragment

export type SignInFieldsFragment = {
  __typename?: 'SignInSuccess'
  auth: {
    __typename?: 'UserAuth'
    token: string
    user: {
      __typename?: 'User'
      id: string
      email: string
      viewingProgramId?: string | null
      viewingAs?: ViewAs | null
      onboardingFlow?: OnboardingFlow | null
      lastViewedProgramId?: string | null
      person: { __typename?: 'Person'; id: string; firstName: string; lastName?: string | null; name: string }
      tenants: Array<{
        __typename?: 'TenantUser'
        role: TenantUserRole
        tenant: { __typename?: 'Tenant'; id: string; name: string }
      }>
      programs: Array<{
        __typename?: 'ProgramUser'
        program: {
          __typename?: 'Program'
          id: string
          name: string
          process: {
            __typename?: 'Process'
            name: string
            template?: { __typename?: 'ProcessTemplate'; name: string; type: ProcessType } | null
          }
          tenant: { __typename?: 'Tenant'; id: string; name: string }
        }
      }>
    }
  }
}

export type ReceiveConfirmationFieldsFragment = {
  __typename?: 'ReceiveConfirmationSuccess'
  auth:
    | {
        __typename?: 'ConfirmationAuth'
        token: string
        confirmation: { __typename?: 'Confirmation'; type: ConfirmationType; value: string }
      }
    | {
        __typename?: 'UserAuth'
        token: string
        user: {
          __typename?: 'User'
          id: string
          email: string
          viewingProgramId?: string | null
          viewingAs?: ViewAs | null
          onboardingFlow?: OnboardingFlow | null
          lastViewedProgramId?: string | null
          person: { __typename?: 'Person'; id: string; firstName: string; lastName?: string | null; name: string }
          tenants: Array<{
            __typename?: 'TenantUser'
            role: TenantUserRole
            tenant: { __typename?: 'Tenant'; id: string; name: string }
          }>
          programs: Array<{
            __typename?: 'ProgramUser'
            program: {
              __typename?: 'Program'
              id: string
              name: string
              process: {
                __typename?: 'Process'
                name: string
                template?: { __typename?: 'ProcessTemplate'; name: string; type: ProcessType } | null
              }
              tenant: { __typename?: 'Tenant'; id: string; name: string }
            }
          }>
        }
      }
}

export type SignupFieldsFragment = {
  __typename?: 'SignupSuccess'
  auth: {
    __typename?: 'UserAuth'
    token: string
    user: {
      __typename?: 'User'
      id: string
      email: string
      viewingProgramId?: string | null
      viewingAs?: ViewAs | null
      onboardingFlow?: OnboardingFlow | null
      lastViewedProgramId?: string | null
      person: { __typename?: 'Person'; id: string; firstName: string; lastName?: string | null; name: string }
      tenants: Array<{
        __typename?: 'TenantUser'
        role: TenantUserRole
        tenant: { __typename?: 'Tenant'; id: string; name: string }
      }>
      programs: Array<{
        __typename?: 'ProgramUser'
        program: {
          __typename?: 'Program'
          id: string
          name: string
          process: {
            __typename?: 'Process'
            name: string
            template?: { __typename?: 'ProcessTemplate'; name: string; type: ProcessType } | null
          }
          tenant: { __typename?: 'Tenant'; id: string; name: string }
        }
      }>
    }
  }
}

export type NotificationFieldsFragment = {
  __typename?: 'Notification'
  id: string
  prefix?: string | null
  text?: string | null
  type: NotificationType
  viewed: boolean
  createdAt: any
  person?: { __typename?: 'Person'; id: string; firstName: string; lastName?: string | null; name: string } | null
}

export type ProgramFieldsFragment = {
  __typename?: 'Program'
  id: string
  name: string
  process: {
    __typename?: 'Process'
    name: string
    template?: { __typename?: 'ProcessTemplate'; name: string; type: ProcessType } | null
  }
  icon?: { __typename?: 'Image'; src: string } | null
  tenant: { __typename?: 'Tenant'; id: string; name: string; createdAt: any; updatedAt: any }
  users: Array<{
    __typename?: 'ProgramUser'
    id: string
    user: {
      __typename?: 'User'
      id: string
      viewingProgramId?: string | null
      viewingAs?: ViewAs | null
      onboardingFlow?: OnboardingFlow | null
      lastViewedProgramId?: string | null
      email: string
      person: {
        __typename?: 'Person'
        id: string
        firstName: string
        lastName?: string | null
        name: string
        email?: string | null
        profileImage?: { __typename?: 'Media'; url: string } | null
      }
      tenants: Array<{
        __typename?: 'TenantUser'
        role: TenantUserRole
        tenant: { __typename?: 'Tenant'; id: string; name: string }
      }>
      programs: Array<{
        __typename?: 'ProgramUser'
        program: {
          __typename?: 'Program'
          id: string
          name: string
          process: {
            __typename?: 'Process'
            name: string
            template?: { __typename?: 'ProcessTemplate'; name: string; type: ProcessType } | null
          }
          tenant: { __typename?: 'Tenant'; id: string; name: string }
        }
      }>
    }
  }>
}

export type InvitationFieldsFragment = {
  __typename?: 'Invitation'
  id: string
  program?: {
    __typename?: 'Program'
    id: string
    name: string
    tenant: { __typename?: 'Tenant'; id: string; name: string }
  } | null
  tenant?: {
    __typename?: 'Tenant'
    id: string
    name: string
    members: Array<{ __typename?: 'TenantMember'; id: string }>
    programs: Array<{ __typename?: 'Program'; id: string; name: string }>
  } | null
  sender: {
    __typename?: 'Person'
    id: string
    firstName: string
    lastName?: string | null
    name: string
    email?: string | null
  }
}

export type MemberInvitationFieldsFragment = {
  __typename?: 'Invitation'
  id: string
  tenant?: {
    __typename?: 'Tenant'
    id: string
    name: string
    members: Array<{
      __typename?: 'TenantMember'
      id: string
      person: {
        __typename?: 'Person'
        name: string
        email?: string | null
        profileImage?: { __typename?: 'Media'; url: string } | null
      }
    }>
    programs: Array<{ __typename?: 'Program'; id: string; name: string }>
  } | null
  sender: {
    __typename?: 'Person'
    id: string
    firstName: string
    lastName?: string | null
    name: string
    email?: string | null
  }
}

export type GuestInvitationFieldsFragment = {
  __typename?: 'Invitation'
  id: string
  program?: {
    __typename?: 'Program'
    id: string
    name: string
    process: { __typename?: 'Process'; id: string; name: string }
    users: Array<{
      __typename?: 'ProgramUser'
      id: string
      user: {
        __typename?: 'User'
        id: string
        person: {
          __typename?: 'Person'
          id: string
          name: string
          email?: string | null
          profileImage?: { __typename?: 'Media'; url: string } | null
        }
      }
    }>
    tenant: {
      __typename?: 'Tenant'
      id: string
      name: string
      programs: Array<{ __typename?: 'Program'; id: string; name: string }>
      members: Array<{
        __typename?: 'TenantMember'
        person: {
          __typename?: 'Person'
          name: string
          email?: string | null
          profileImage?: { __typename?: 'Media'; url: string } | null
        }
      }>
    }
  } | null
  sender: {
    __typename?: 'Person'
    id: string
    firstName: string
    lastName?: string | null
    name: string
    email?: string | null
  }
}

export type TenantFieldsFragment = { __typename?: 'Tenant'; id: string; name: string; createdAt: any; updatedAt: any }

export type SlideFieldsFragment = {
  __typename?: 'Slide'
  title: string
  subtitle?: string | null
  text?: string | null
  slider: Slider
  image?: { __typename?: 'Media'; url: string } | null
}

export type ProcessTemplateFieldsFragment = {
  __typename?: 'ProcessTemplate'
  id: string
  name: string
  type: ProcessType
}

export type SignInMutationVariables = Exact<{
  data?: InputMaybe<SignInInput>
}>

export type SignInMutation = {
  __typename?: 'Mutation'
  signIn:
    | { __typename?: 'SignInError'; message: string }
    | {
        __typename?: 'SignInSuccess'
        message: string
        auth: {
          __typename?: 'UserAuth'
          token: string
          user: {
            __typename?: 'User'
            id: string
            email: string
            viewingProgramId?: string | null
            viewingAs?: ViewAs | null
            onboardingFlow?: OnboardingFlow | null
            lastViewedProgramId?: string | null
            person: { __typename?: 'Person'; id: string; firstName: string; lastName?: string | null; name: string }
            tenants: Array<{
              __typename?: 'TenantUser'
              role: TenantUserRole
              tenant: { __typename?: 'Tenant'; id: string; name: string }
            }>
            programs: Array<{
              __typename?: 'ProgramUser'
              program: {
                __typename?: 'Program'
                id: string
                name: string
                process: {
                  __typename?: 'Process'
                  name: string
                  template?: { __typename?: 'ProcessTemplate'; name: string; type: ProcessType } | null
                }
                tenant: { __typename?: 'Tenant'; id: string; name: string }
              }
            }>
          }
        }
      }
}

export type UpsertNotificationsMutationVariables = Exact<{
  data: UpsertNotificationsInput
}>

export type UpsertNotificationsMutation = {
  __typename?: 'Mutation'
  upsertNotifications: Array<{
    __typename?: 'Notification'
    id: string
    prefix?: string | null
    text?: string | null
    type: NotificationType
    viewed: boolean
    createdAt: any
    person?: { __typename?: 'Person'; id: string; firstName: string; lastName?: string | null; name: string } | null
  }>
}

export type SendConfirmationMutationVariables = Exact<{
  data: SendConfirmationInput
}>

export type SendConfirmationMutation = {
  __typename?: 'Mutation'
  sendConfirmation:
    | { __typename?: 'SendConfirmationError'; message: string }
    | {
        __typename?: 'SendConfirmationSuccess'
        message: string
        confirmation: {
          __typename?: 'Confirmation'
          type: ConfirmationType
          value: string
          purpose: ConfirmationPurpose
          credential: { __typename?: 'ClientCredential'; id: string }
        }
      }
}

export type ReceiveConfirmationMutationVariables = Exact<{
  data: ReceiveConfirmationInput
}>

export type ReceiveConfirmationMutation = {
  __typename?: 'Mutation'
  receiveConfirmation:
    | { __typename?: 'ReceiveConfirmationError'; message: string }
    | {
        __typename?: 'ReceiveConfirmationSuccess'
        message: string
        auth:
          | {
              __typename?: 'ConfirmationAuth'
              token: string
              confirmation: { __typename?: 'Confirmation'; type: ConfirmationType; value: string }
            }
          | {
              __typename?: 'UserAuth'
              token: string
              user: {
                __typename?: 'User'
                id: string
                email: string
                viewingProgramId?: string | null
                viewingAs?: ViewAs | null
                onboardingFlow?: OnboardingFlow | null
                lastViewedProgramId?: string | null
                person: { __typename?: 'Person'; id: string; firstName: string; lastName?: string | null; name: string }
                tenants: Array<{
                  __typename?: 'TenantUser'
                  role: TenantUserRole
                  tenant: { __typename?: 'Tenant'; id: string; name: string }
                }>
                programs: Array<{
                  __typename?: 'ProgramUser'
                  program: {
                    __typename?: 'Program'
                    id: string
                    name: string
                    process: {
                      __typename?: 'Process'
                      name: string
                      template?: { __typename?: 'ProcessTemplate'; name: string; type: ProcessType } | null
                    }
                    tenant: { __typename?: 'Tenant'; id: string; name: string }
                  }
                }>
              }
            }
      }
}

export type SignUpMutationVariables = Exact<{
  data: SignupInput
}>

export type SignUpMutation = {
  __typename?: 'Mutation'
  signup:
    | { __typename?: 'SignupError'; message: string }
    | {
        __typename?: 'SignupSuccess'
        message: string
        auth: {
          __typename?: 'UserAuth'
          token: string
          user: {
            __typename?: 'User'
            id: string
            email: string
            viewingProgramId?: string | null
            viewingAs?: ViewAs | null
            onboardingFlow?: OnboardingFlow | null
            lastViewedProgramId?: string | null
            person: { __typename?: 'Person'; id: string; firstName: string; lastName?: string | null; name: string }
            tenants: Array<{
              __typename?: 'TenantUser'
              role: TenantUserRole
              tenant: { __typename?: 'Tenant'; id: string; name: string }
            }>
            programs: Array<{
              __typename?: 'ProgramUser'
              program: {
                __typename?: 'Program'
                id: string
                name: string
                process: {
                  __typename?: 'Process'
                  name: string
                  template?: { __typename?: 'ProcessTemplate'; name: string; type: ProcessType } | null
                }
                tenant: { __typename?: 'Tenant'; id: string; name: string }
              }
            }>
          }
        }
      }
}

export type RegisterTenantMutationVariables = Exact<{
  data: RegisterTenantInput
}>

export type RegisterTenantMutation = {
  __typename?: 'Mutation'
  registerTenant:
    | { __typename?: 'RegisterTenantError'; message: string }
    | {
        __typename?: 'RegisterTenantSuccess'
        message: string
        tenant: { __typename?: 'Tenant'; id: string; name: string; createdAt: any; updatedAt: any }
      }
}

export type CreateProgramMutationVariables = Exact<{
  data: CreateProgramInput
}>

export type CreateProgramMutation = {
  __typename?: 'Mutation'
  createProgram:
    | { __typename?: 'CreateProgramError'; message: string }
    | {
        __typename?: 'CreateProgramSuccess'
        message: string
        program: {
          __typename?: 'Program'
          id: string
          name: string
          process: {
            __typename?: 'Process'
            name: string
            template?: { __typename?: 'ProcessTemplate'; name: string; type: ProcessType } | null
          }
          icon?: { __typename?: 'Image'; src: string } | null
          tenant: { __typename?: 'Tenant'; id: string; name: string; createdAt: any; updatedAt: any }
          users: Array<{
            __typename?: 'ProgramUser'
            id: string
            user: {
              __typename?: 'User'
              id: string
              viewingProgramId?: string | null
              viewingAs?: ViewAs | null
              onboardingFlow?: OnboardingFlow | null
              lastViewedProgramId?: string | null
              email: string
              person: {
                __typename?: 'Person'
                id: string
                firstName: string
                lastName?: string | null
                name: string
                email?: string | null
                profileImage?: { __typename?: 'Media'; url: string } | null
              }
              tenants: Array<{
                __typename?: 'TenantUser'
                role: TenantUserRole
                tenant: { __typename?: 'Tenant'; id: string; name: string }
              }>
              programs: Array<{
                __typename?: 'ProgramUser'
                program: {
                  __typename?: 'Program'
                  id: string
                  name: string
                  process: {
                    __typename?: 'Process'
                    name: string
                    template?: { __typename?: 'ProcessTemplate'; name: string; type: ProcessType } | null
                  }
                  tenant: { __typename?: 'Tenant'; id: string; name: string }
                }
              }>
            }
          }>
        }
      }
}

export type JoinProgramMutationVariables = Exact<{
  where: ProgramWhereUniqueInput
}>

export type JoinProgramMutation = {
  __typename?: 'Mutation'
  joinProgram:
    | { __typename?: 'JoinProgramError'; message: string }
    | { __typename?: 'JoinProgramSuccess'; message: string }
}

export type AcceptInvitationMutationVariables = Exact<{
  where: InvitationWhereUniqueInput
}>

export type AcceptInvitationMutation = {
  __typename?: 'Mutation'
  acceptInvitation?:
    | { __typename?: 'AcceptInvitationError'; message: string }
    | { __typename?: 'AcceptInvitationSuccess'; message: string }
    | null
}

export type DeclineInvitationMutationVariables = Exact<{
  where: InvitationWhereUniqueInput
}>

export type DeclineInvitationMutation = {
  __typename?: 'Mutation'
  declineInvitation?:
    | { __typename?: 'DeclineInvitationError'; message: string }
    | { __typename?: 'DeclineInvitationSuccess'; message: string }
    | null
}

export type MeQueryVariables = Exact<{ [key: string]: never }>

export type MeQuery = {
  __typename?: 'Query'
  me?: {
    __typename?: 'User'
    id: string
    viewingProgramId?: string | null
    viewingAs?: ViewAs | null
    onboardingFlow?: OnboardingFlow | null
    lastViewedProgramId?: string | null
    email: string
    person: {
      __typename?: 'Person'
      id: string
      firstName: string
      lastName?: string | null
      name: string
      email?: string | null
      profileImage?: { __typename?: 'Media'; url: string } | null
    }
    tenants: Array<{
      __typename?: 'TenantUser'
      role: TenantUserRole
      tenant: { __typename?: 'Tenant'; id: string; name: string }
    }>
    programs: Array<{
      __typename?: 'ProgramUser'
      program: {
        __typename?: 'Program'
        id: string
        name: string
        process: {
          __typename?: 'Process'
          name: string
          template?: { __typename?: 'ProcessTemplate'; name: string; type: ProcessType } | null
        }
        tenant: { __typename?: 'Tenant'; id: string; name: string }
      }
    }>
  } | null
}

export type NotificationsQueryVariables = Exact<{
  data: NotificationInput
}>

export type NotificationsQuery = {
  __typename?: 'Query'
  notifications: Array<{
    __typename?: 'Notification'
    id: string
    prefix?: string | null
    text?: string | null
    type: NotificationType
    viewed: boolean
    createdAt: any
    person?: { __typename?: 'Person'; id: string; firstName: string; lastName?: string | null; name: string } | null
  }>
}

export type ProgramsQueryVariables = Exact<{
  where?: InputMaybe<ProgramWhereInput>
}>

export type ProgramsQuery = {
  __typename?: 'Query'
  programs: {
    __typename?: 'Programs'
    list: Array<{
      __typename?: 'Program'
      id: string
      name: string
      process: {
        __typename?: 'Process'
        name: string
        template?: { __typename?: 'ProcessTemplate'; name: string; type: ProcessType } | null
      }
      icon?: { __typename?: 'Image'; src: string } | null
      tenant: { __typename?: 'Tenant'; id: string; name: string; createdAt: any; updatedAt: any }
      users: Array<{
        __typename?: 'ProgramUser'
        id: string
        user: {
          __typename?: 'User'
          id: string
          viewingProgramId?: string | null
          viewingAs?: ViewAs | null
          onboardingFlow?: OnboardingFlow | null
          lastViewedProgramId?: string | null
          email: string
          person: {
            __typename?: 'Person'
            id: string
            firstName: string
            lastName?: string | null
            name: string
            email?: string | null
            profileImage?: { __typename?: 'Media'; url: string } | null
          }
          tenants: Array<{
            __typename?: 'TenantUser'
            role: TenantUserRole
            tenant: { __typename?: 'Tenant'; id: string; name: string }
          }>
          programs: Array<{
            __typename?: 'ProgramUser'
            program: {
              __typename?: 'Program'
              id: string
              name: string
              process: {
                __typename?: 'Process'
                name: string
                template?: { __typename?: 'ProcessTemplate'; name: string; type: ProcessType } | null
              }
              tenant: { __typename?: 'Tenant'; id: string; name: string }
            }
          }>
        }
      }>
    }>
  }
}

export type MemberInvitationsQueryVariables = Exact<{ [key: string]: never }>

export type MemberInvitationsQuery = {
  __typename?: 'Query'
  invitations: {
    __typename?: 'Invitations'
    list: Array<{
      __typename?: 'Invitation'
      id: string
      tenant?: {
        __typename?: 'Tenant'
        id: string
        name: string
        members: Array<{
          __typename?: 'TenantMember'
          id: string
          person: {
            __typename?: 'Person'
            name: string
            email?: string | null
            profileImage?: { __typename?: 'Media'; url: string } | null
          }
        }>
        programs: Array<{ __typename?: 'Program'; id: string; name: string }>
      } | null
      sender: {
        __typename?: 'Person'
        id: string
        firstName: string
        lastName?: string | null
        name: string
        email?: string | null
      }
    }>
  }
}

export type GuestInvitationsQueryVariables = Exact<{
  where?: InputMaybe<InvitationWhereInput>
}>

export type GuestInvitationsQuery = {
  __typename?: 'Query'
  invitations: {
    __typename?: 'Invitations'
    list: Array<{
      __typename?: 'Invitation'
      id: string
      program?: {
        __typename?: 'Program'
        id: string
        name: string
        process: { __typename?: 'Process'; id: string; name: string }
        users: Array<{
          __typename?: 'ProgramUser'
          id: string
          user: {
            __typename?: 'User'
            id: string
            person: {
              __typename?: 'Person'
              id: string
              name: string
              email?: string | null
              profileImage?: { __typename?: 'Media'; url: string } | null
            }
          }
        }>
        tenant: {
          __typename?: 'Tenant'
          id: string
          name: string
          programs: Array<{ __typename?: 'Program'; id: string; name: string }>
          members: Array<{
            __typename?: 'TenantMember'
            person: {
              __typename?: 'Person'
              name: string
              email?: string | null
              profileImage?: { __typename?: 'Media'; url: string } | null
            }
          }>
        }
      } | null
      sender: {
        __typename?: 'Person'
        id: string
        firstName: string
        lastName?: string | null
        name: string
        email?: string | null
      }
    }>
  }
}

export type SlidesQueryVariables = Exact<{
  where: SlideWhereInput
}>

export type SlidesQuery = {
  __typename?: 'Query'
  slides: {
    __typename?: 'Slides'
    list: Array<{
      __typename?: 'Slide'
      title: string
      subtitle?: string | null
      text?: string | null
      slider: Slider
      image?: { __typename?: 'Media'; url: string } | null
    }>
  }
}

export type ProcessTemplatesQueryVariables = Exact<{
  where?: InputMaybe<ProcessTemplateWhereInput>
}>

export type ProcessTemplatesQuery = {
  __typename?: 'Query'
  processTemplates: {
    __typename?: 'ProcessTemplates'
    list: Array<{ __typename?: 'ProcessTemplate'; id: string; name: string; type: ProcessType }>
  }
}

export const PersonFieldsFragmentDoc = gql`
  fragment personFields on Person {
    id
    firstName
    lastName
  }
`
export const ConfirmationFieldsFragmentDoc = gql`
  fragment confirmationFields on Confirmation {
    type
    value
    purpose
    credential {
      id
    }
  }
`
export const UserAuthFieldsFragmentDoc = gql`
  fragment userAuthFields on UserAuth {
    token
    user {
      id
      email
      viewingProgramId @client
      viewingAs @client
      onboardingFlow @client
      lastViewedProgramId
      person {
        id
        firstName
        lastName
        name
      }
      tenants {
        role
        tenant {
          id
          name
        }
      }
      programs {
        program {
          id
          name
          process {
            name
            template {
              name
              type
            }
          }
          tenant {
            id
            name
          }
        }
      }
    }
  }
`
export const ConfirmationAuthFieldsFragmentDoc = gql`
  fragment confirmationAuthFields on ConfirmationAuth {
    token
    confirmation {
      type
      value
    }
  }
`
export const AuthFieldsFragmentDoc = gql`
  fragment authFields on Auth {
    ... on UserAuth {
      ...userAuthFields
    }
    ... on ConfirmationAuth {
      ...confirmationAuthFields
    }
  }
  ${UserAuthFieldsFragmentDoc}
  ${ConfirmationAuthFieldsFragmentDoc}
`
export const SignInFieldsFragmentDoc = gql`
  fragment signInFields on SignInSuccess {
    auth {
      ...authFields
    }
  }
  ${AuthFieldsFragmentDoc}
`
export const ReceiveConfirmationFieldsFragmentDoc = gql`
  fragment receiveConfirmationFields on ReceiveConfirmationSuccess {
    auth {
      ...authFields
    }
  }
  ${AuthFieldsFragmentDoc}
`
export const SignupFieldsFragmentDoc = gql`
  fragment signupFields on SignupSuccess {
    auth {
      ...authFields
    }
  }
  ${AuthFieldsFragmentDoc}
`
export const NotificationFieldsFragmentDoc = gql`
  fragment notificationFields on Notification {
    id
    prefix
    text
    type
    viewed
    createdAt
    person {
      id
      firstName
      lastName
      name
    }
  }
`
export const UserFieldsFragmentDoc = gql`
  fragment userFields on User {
    id
    viewingProgramId @client
    viewingAs @client
    onboardingFlow @client
    lastViewedProgramId
    person {
      id
      firstName
      lastName
      name
      email
      profileImage {
        url
      }
    }
    tenants {
      role
      tenant {
        id
        name
      }
    }
    programs {
      program {
        id
        name
        process {
          name
          template {
            name
            type
          }
        }
        tenant {
          id
          name
        }
      }
    }
    email
  }
`
export const ProgramFieldsFragmentDoc = gql`
  fragment programFields on Program {
    id
    name
    process {
      name
      template {
        name
        type
      }
    }
    icon {
      src
    }
    tenant {
      id
      name
      createdAt
      updatedAt
    }
    users {
      id
      user {
        ...userFields
      }
    }
  }
  ${UserFieldsFragmentDoc}
`
export const InvitationFieldsFragmentDoc = gql`
  fragment invitationFields on Invitation {
    id
    program {
      id
      name
      tenant {
        id
        name
      }
    }
    tenant {
      id
      name
      members {
        id
      }
      programs {
        id
        name
      }
    }
    sender {
      id
      firstName
      lastName
      name
      email
    }
  }
`
export const MemberInvitationFieldsFragmentDoc = gql`
  fragment memberInvitationFields on Invitation {
    id
    tenant {
      id
      name
      members {
        id
        person {
          name
          email
          profileImage {
            url
          }
        }
      }
      programs {
        id
        name
      }
    }
    sender {
      id
      firstName
      lastName
      name
      email
    }
  }
`
export const GuestInvitationFieldsFragmentDoc = gql`
  fragment guestInvitationFields on Invitation {
    id
    program {
      id
      name
      process {
        id
        name
      }
      users {
        id
        user {
          id
          person {
            id
            name
            email
            profileImage {
              url
            }
          }
        }
      }
      tenant {
        id
        name
        programs {
          id
          name
        }
        members {
          person {
            name
            email
            profileImage {
              url
            }
          }
        }
      }
    }
    sender {
      id
      firstName
      lastName
      name
      email
    }
  }
`
export const TenantFieldsFragmentDoc = gql`
  fragment tenantFields on Tenant {
    id
    name
    createdAt
    updatedAt
  }
`
export const SlideFieldsFragmentDoc = gql`
  fragment slideFields on Slide {
    title
    subtitle
    text
    image {
      url
    }
    slider
  }
`
export const ProcessTemplateFieldsFragmentDoc = gql`
  fragment processTemplateFields on ProcessTemplate {
    id
    name
    type
  }
`
export const SignInDocument = gql`
  mutation SignIn($data: SignInInput) {
    signIn(data: $data) {
      ... on MutationResponse {
        message
      }
      ... on SignInSuccess {
        ...signInFields
      }
      ... on SignInError {
        message
      }
    }
  }
  ${SignInFieldsFragmentDoc}
`
export type SignInMutationFn = Apollo.MutationFunction<SignInMutation, SignInMutationVariables>

/**
 * __useSignInMutation__
 *
 * To run a mutation, you first call `useSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInMutation, { data, loading, error }] = useSignInMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSignInMutation(baseOptions?: Apollo.MutationHookOptions<SignInMutation, SignInMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SignInMutation, SignInMutationVariables>(SignInDocument, options)
}
export type SignInMutationHookResult = ReturnType<typeof useSignInMutation>
export type SignInMutationResult = Apollo.MutationResult<SignInMutation>
export type SignInMutationOptions = Apollo.BaseMutationOptions<SignInMutation, SignInMutationVariables>
export const UpsertNotificationsDocument = gql`
  mutation UpsertNotifications($data: UpsertNotificationsInput!) {
    upsertNotifications(data: $data) {
      ...notificationFields
    }
  }
  ${NotificationFieldsFragmentDoc}
`
export type UpsertNotificationsMutationFn = Apollo.MutationFunction<
  UpsertNotificationsMutation,
  UpsertNotificationsMutationVariables
>

/**
 * __useUpsertNotificationsMutation__
 *
 * To run a mutation, you first call `useUpsertNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertNotificationsMutation, { data, loading, error }] = useUpsertNotificationsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpsertNotificationsMutation(
  baseOptions?: Apollo.MutationHookOptions<UpsertNotificationsMutation, UpsertNotificationsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpsertNotificationsMutation, UpsertNotificationsMutationVariables>(
    UpsertNotificationsDocument,
    options,
  )
}
export type UpsertNotificationsMutationHookResult = ReturnType<typeof useUpsertNotificationsMutation>
export type UpsertNotificationsMutationResult = Apollo.MutationResult<UpsertNotificationsMutation>
export type UpsertNotificationsMutationOptions = Apollo.BaseMutationOptions<
  UpsertNotificationsMutation,
  UpsertNotificationsMutationVariables
>
export const SendConfirmationDocument = gql`
  mutation SendConfirmation($data: SendConfirmationInput!) {
    sendConfirmation(data: $data) {
      ... on MutationResponse {
        message
      }
      ... on SendConfirmationError {
        message
      }
      ... on SendConfirmationSuccess {
        confirmation {
          ...confirmationFields
        }
      }
    }
  }
  ${ConfirmationFieldsFragmentDoc}
`
export type SendConfirmationMutationFn = Apollo.MutationFunction<
  SendConfirmationMutation,
  SendConfirmationMutationVariables
>

/**
 * __useSendConfirmationMutation__
 *
 * To run a mutation, you first call `useSendConfirmationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendConfirmationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendConfirmationMutation, { data, loading, error }] = useSendConfirmationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSendConfirmationMutation(
  baseOptions?: Apollo.MutationHookOptions<SendConfirmationMutation, SendConfirmationMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SendConfirmationMutation, SendConfirmationMutationVariables>(
    SendConfirmationDocument,
    options,
  )
}
export type SendConfirmationMutationHookResult = ReturnType<typeof useSendConfirmationMutation>
export type SendConfirmationMutationResult = Apollo.MutationResult<SendConfirmationMutation>
export type SendConfirmationMutationOptions = Apollo.BaseMutationOptions<
  SendConfirmationMutation,
  SendConfirmationMutationVariables
>
export const ReceiveConfirmationDocument = gql`
  mutation ReceiveConfirmation($data: ReceiveConfirmationInput!) {
    receiveConfirmation(data: $data) {
      ... on MutationResponse {
        message
      }
      ... on ReceiveConfirmationError {
        message
      }
      ... on ReceiveConfirmationSuccess {
        ...receiveConfirmationFields
      }
    }
  }
  ${ReceiveConfirmationFieldsFragmentDoc}
`
export type ReceiveConfirmationMutationFn = Apollo.MutationFunction<
  ReceiveConfirmationMutation,
  ReceiveConfirmationMutationVariables
>

/**
 * __useReceiveConfirmationMutation__
 *
 * To run a mutation, you first call `useReceiveConfirmationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReceiveConfirmationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [receiveConfirmationMutation, { data, loading, error }] = useReceiveConfirmationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useReceiveConfirmationMutation(
  baseOptions?: Apollo.MutationHookOptions<ReceiveConfirmationMutation, ReceiveConfirmationMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ReceiveConfirmationMutation, ReceiveConfirmationMutationVariables>(
    ReceiveConfirmationDocument,
    options,
  )
}
export type ReceiveConfirmationMutationHookResult = ReturnType<typeof useReceiveConfirmationMutation>
export type ReceiveConfirmationMutationResult = Apollo.MutationResult<ReceiveConfirmationMutation>
export type ReceiveConfirmationMutationOptions = Apollo.BaseMutationOptions<
  ReceiveConfirmationMutation,
  ReceiveConfirmationMutationVariables
>
export const SignUpDocument = gql`
  mutation SignUp($data: SignupInput!) {
    signup(data: $data) {
      ... on MutationResponse {
        message
      }
      ... on SignupError {
        message
      }
      ... on SignupSuccess {
        ...signupFields
      }
    }
  }
  ${SignupFieldsFragmentDoc}
`
export type SignUpMutationFn = Apollo.MutationFunction<SignUpMutation, SignUpMutationVariables>

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSignUpMutation(baseOptions?: Apollo.MutationHookOptions<SignUpMutation, SignUpMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SignUpMutation, SignUpMutationVariables>(SignUpDocument, options)
}
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>
export type SignUpMutationResult = Apollo.MutationResult<SignUpMutation>
export type SignUpMutationOptions = Apollo.BaseMutationOptions<SignUpMutation, SignUpMutationVariables>
export const RegisterTenantDocument = gql`
  mutation RegisterTenant($data: RegisterTenantInput!) {
    registerTenant(data: $data) {
      ... on MutationResponse {
        message
      }
      ... on RegisterTenantError {
        message
      }
      ... on RegisterTenantSuccess {
        tenant {
          ...tenantFields
        }
      }
    }
  }
  ${TenantFieldsFragmentDoc}
`
export type RegisterTenantMutationFn = Apollo.MutationFunction<RegisterTenantMutation, RegisterTenantMutationVariables>

/**
 * __useRegisterTenantMutation__
 *
 * To run a mutation, you first call `useRegisterTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerTenantMutation, { data, loading, error }] = useRegisterTenantMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRegisterTenantMutation(
  baseOptions?: Apollo.MutationHookOptions<RegisterTenantMutation, RegisterTenantMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RegisterTenantMutation, RegisterTenantMutationVariables>(RegisterTenantDocument, options)
}
export type RegisterTenantMutationHookResult = ReturnType<typeof useRegisterTenantMutation>
export type RegisterTenantMutationResult = Apollo.MutationResult<RegisterTenantMutation>
export type RegisterTenantMutationOptions = Apollo.BaseMutationOptions<
  RegisterTenantMutation,
  RegisterTenantMutationVariables
>
export const CreateProgramDocument = gql`
  mutation CreateProgram($data: CreateProgramInput!) {
    createProgram(data: $data) {
      ... on MutationResponse {
        message
      }
      ... on CreateProgramError {
        message
      }
      ... on CreateProgramSuccess {
        program {
          ...programFields
        }
      }
    }
  }
  ${ProgramFieldsFragmentDoc}
`
export type CreateProgramMutationFn = Apollo.MutationFunction<CreateProgramMutation, CreateProgramMutationVariables>

/**
 * __useCreateProgramMutation__
 *
 * To run a mutation, you first call `useCreateProgramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProgramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProgramMutation, { data, loading, error }] = useCreateProgramMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateProgramMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateProgramMutation, CreateProgramMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateProgramMutation, CreateProgramMutationVariables>(CreateProgramDocument, options)
}
export type CreateProgramMutationHookResult = ReturnType<typeof useCreateProgramMutation>
export type CreateProgramMutationResult = Apollo.MutationResult<CreateProgramMutation>
export type CreateProgramMutationOptions = Apollo.BaseMutationOptions<
  CreateProgramMutation,
  CreateProgramMutationVariables
>
export const JoinProgramDocument = gql`
  mutation JoinProgram($where: ProgramWhereUniqueInput!) {
    joinProgram(where: $where) {
      ... on MutationResponse {
        message
      }
    }
  }
`
export type JoinProgramMutationFn = Apollo.MutationFunction<JoinProgramMutation, JoinProgramMutationVariables>

/**
 * __useJoinProgramMutation__
 *
 * To run a mutation, you first call `useJoinProgramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinProgramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinProgramMutation, { data, loading, error }] = useJoinProgramMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useJoinProgramMutation(
  baseOptions?: Apollo.MutationHookOptions<JoinProgramMutation, JoinProgramMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<JoinProgramMutation, JoinProgramMutationVariables>(JoinProgramDocument, options)
}
export type JoinProgramMutationHookResult = ReturnType<typeof useJoinProgramMutation>
export type JoinProgramMutationResult = Apollo.MutationResult<JoinProgramMutation>
export type JoinProgramMutationOptions = Apollo.BaseMutationOptions<JoinProgramMutation, JoinProgramMutationVariables>
export const AcceptInvitationDocument = gql`
  mutation AcceptInvitation($where: InvitationWhereUniqueInput!) {
    acceptInvitation(where: $where) {
      ... on MutationResponse {
        message
      }
    }
  }
`
export type AcceptInvitationMutationFn = Apollo.MutationFunction<
  AcceptInvitationMutation,
  AcceptInvitationMutationVariables
>

/**
 * __useAcceptInvitationMutation__
 *
 * To run a mutation, you first call `useAcceptInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptInvitationMutation, { data, loading, error }] = useAcceptInvitationMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAcceptInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<AcceptInvitationMutation, AcceptInvitationMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AcceptInvitationMutation, AcceptInvitationMutationVariables>(
    AcceptInvitationDocument,
    options,
  )
}
export type AcceptInvitationMutationHookResult = ReturnType<typeof useAcceptInvitationMutation>
export type AcceptInvitationMutationResult = Apollo.MutationResult<AcceptInvitationMutation>
export type AcceptInvitationMutationOptions = Apollo.BaseMutationOptions<
  AcceptInvitationMutation,
  AcceptInvitationMutationVariables
>
export const DeclineInvitationDocument = gql`
  mutation DeclineInvitation($where: InvitationWhereUniqueInput!) {
    declineInvitation(where: $where) {
      ... on MutationResponse {
        message
      }
    }
  }
`
export type DeclineInvitationMutationFn = Apollo.MutationFunction<
  DeclineInvitationMutation,
  DeclineInvitationMutationVariables
>

/**
 * __useDeclineInvitationMutation__
 *
 * To run a mutation, you first call `useDeclineInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeclineInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [declineInvitationMutation, { data, loading, error }] = useDeclineInvitationMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeclineInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<DeclineInvitationMutation, DeclineInvitationMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeclineInvitationMutation, DeclineInvitationMutationVariables>(
    DeclineInvitationDocument,
    options,
  )
}
export type DeclineInvitationMutationHookResult = ReturnType<typeof useDeclineInvitationMutation>
export type DeclineInvitationMutationResult = Apollo.MutationResult<DeclineInvitationMutation>
export type DeclineInvitationMutationOptions = Apollo.BaseMutationOptions<
  DeclineInvitationMutation,
  DeclineInvitationMutationVariables
>
export const MeDocument = gql`
  query Me {
    me {
      ...userFields
    }
  }
  ${UserFieldsFragmentDoc}
`

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options)
}
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options)
}
export function useMeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MeQuery, MeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<MeQuery, MeQueryVariables>(MeDocument, options)
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>
export type MeSuspenseQueryHookResult = ReturnType<typeof useMeSuspenseQuery>
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>
export const NotificationsDocument = gql`
  query Notifications($data: NotificationInput!) {
    notifications(data: $data) {
      ...notificationFields
    }
  }
  ${NotificationFieldsFragmentDoc}
`

/**
 * __useNotificationsQuery__
 *
 * To run a query within a React component, call `useNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useNotificationsQuery(
  baseOptions: Apollo.QueryHookOptions<NotificationsQuery, NotificationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options)
}
export function useNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<NotificationsQuery, NotificationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options)
}
export function useNotificationsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<NotificationsQuery, NotificationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options)
}
export type NotificationsQueryHookResult = ReturnType<typeof useNotificationsQuery>
export type NotificationsLazyQueryHookResult = ReturnType<typeof useNotificationsLazyQuery>
export type NotificationsSuspenseQueryHookResult = ReturnType<typeof useNotificationsSuspenseQuery>
export type NotificationsQueryResult = Apollo.QueryResult<NotificationsQuery, NotificationsQueryVariables>
export const ProgramsDocument = gql`
  query Programs($where: ProgramWhereInput) {
    programs(where: $where) {
      list {
        ...programFields
      }
    }
  }
  ${ProgramFieldsFragmentDoc}
`

/**
 * __useProgramsQuery__
 *
 * To run a query within a React component, call `useProgramsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProgramsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useProgramsQuery(baseOptions?: Apollo.QueryHookOptions<ProgramsQuery, ProgramsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ProgramsQuery, ProgramsQueryVariables>(ProgramsDocument, options)
}
export function useProgramsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProgramsQuery, ProgramsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ProgramsQuery, ProgramsQueryVariables>(ProgramsDocument, options)
}
export function useProgramsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ProgramsQuery, ProgramsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ProgramsQuery, ProgramsQueryVariables>(ProgramsDocument, options)
}
export type ProgramsQueryHookResult = ReturnType<typeof useProgramsQuery>
export type ProgramsLazyQueryHookResult = ReturnType<typeof useProgramsLazyQuery>
export type ProgramsSuspenseQueryHookResult = ReturnType<typeof useProgramsSuspenseQuery>
export type ProgramsQueryResult = Apollo.QueryResult<ProgramsQuery, ProgramsQueryVariables>
export const MemberInvitationsDocument = gql`
  query MemberInvitations {
    invitations(type: MEMBER) {
      list {
        ...memberInvitationFields
      }
    }
  }
  ${MemberInvitationFieldsFragmentDoc}
`

/**
 * __useMemberInvitationsQuery__
 *
 * To run a query within a React component, call `useMemberInvitationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberInvitationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberInvitationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMemberInvitationsQuery(
  baseOptions?: Apollo.QueryHookOptions<MemberInvitationsQuery, MemberInvitationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<MemberInvitationsQuery, MemberInvitationsQueryVariables>(MemberInvitationsDocument, options)
}
export function useMemberInvitationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MemberInvitationsQuery, MemberInvitationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<MemberInvitationsQuery, MemberInvitationsQueryVariables>(
    MemberInvitationsDocument,
    options,
  )
}
export function useMemberInvitationsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<MemberInvitationsQuery, MemberInvitationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<MemberInvitationsQuery, MemberInvitationsQueryVariables>(
    MemberInvitationsDocument,
    options,
  )
}
export type MemberInvitationsQueryHookResult = ReturnType<typeof useMemberInvitationsQuery>
export type MemberInvitationsLazyQueryHookResult = ReturnType<typeof useMemberInvitationsLazyQuery>
export type MemberInvitationsSuspenseQueryHookResult = ReturnType<typeof useMemberInvitationsSuspenseQuery>
export type MemberInvitationsQueryResult = Apollo.QueryResult<MemberInvitationsQuery, MemberInvitationsQueryVariables>
export const GuestInvitationsDocument = gql`
  query GuestInvitations($where: InvitationWhereInput) {
    invitations(type: GUEST, where: $where) {
      list {
        ...guestInvitationFields
      }
    }
  }
  ${GuestInvitationFieldsFragmentDoc}
`

/**
 * __useGuestInvitationsQuery__
 *
 * To run a query within a React component, call `useGuestInvitationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGuestInvitationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGuestInvitationsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGuestInvitationsQuery(
  baseOptions?: Apollo.QueryHookOptions<GuestInvitationsQuery, GuestInvitationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GuestInvitationsQuery, GuestInvitationsQueryVariables>(GuestInvitationsDocument, options)
}
export function useGuestInvitationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GuestInvitationsQuery, GuestInvitationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GuestInvitationsQuery, GuestInvitationsQueryVariables>(GuestInvitationsDocument, options)
}
export function useGuestInvitationsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GuestInvitationsQuery, GuestInvitationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GuestInvitationsQuery, GuestInvitationsQueryVariables>(
    GuestInvitationsDocument,
    options,
  )
}
export type GuestInvitationsQueryHookResult = ReturnType<typeof useGuestInvitationsQuery>
export type GuestInvitationsLazyQueryHookResult = ReturnType<typeof useGuestInvitationsLazyQuery>
export type GuestInvitationsSuspenseQueryHookResult = ReturnType<typeof useGuestInvitationsSuspenseQuery>
export type GuestInvitationsQueryResult = Apollo.QueryResult<GuestInvitationsQuery, GuestInvitationsQueryVariables>
export const SlidesDocument = gql`
  query Slides($where: SlideWhereInput!) {
    slides(where: $where) {
      list {
        ...slideFields
      }
    }
  }
  ${SlideFieldsFragmentDoc}
`

/**
 * __useSlidesQuery__
 *
 * To run a query within a React component, call `useSlidesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSlidesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSlidesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSlidesQuery(baseOptions: Apollo.QueryHookOptions<SlidesQuery, SlidesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SlidesQuery, SlidesQueryVariables>(SlidesDocument, options)
}
export function useSlidesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SlidesQuery, SlidesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SlidesQuery, SlidesQueryVariables>(SlidesDocument, options)
}
export function useSlidesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<SlidesQuery, SlidesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<SlidesQuery, SlidesQueryVariables>(SlidesDocument, options)
}
export type SlidesQueryHookResult = ReturnType<typeof useSlidesQuery>
export type SlidesLazyQueryHookResult = ReturnType<typeof useSlidesLazyQuery>
export type SlidesSuspenseQueryHookResult = ReturnType<typeof useSlidesSuspenseQuery>
export type SlidesQueryResult = Apollo.QueryResult<SlidesQuery, SlidesQueryVariables>
export const ProcessTemplatesDocument = gql`
  query ProcessTemplates($where: ProcessTemplateWhereInput) {
    processTemplates(where: $where) {
      list {
        ...processTemplateFields
      }
    }
  }
  ${ProcessTemplateFieldsFragmentDoc}
`

/**
 * __useProcessTemplatesQuery__
 *
 * To run a query within a React component, call `useProcessTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProcessTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProcessTemplatesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useProcessTemplatesQuery(
  baseOptions?: Apollo.QueryHookOptions<ProcessTemplatesQuery, ProcessTemplatesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ProcessTemplatesQuery, ProcessTemplatesQueryVariables>(ProcessTemplatesDocument, options)
}
export function useProcessTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProcessTemplatesQuery, ProcessTemplatesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ProcessTemplatesQuery, ProcessTemplatesQueryVariables>(ProcessTemplatesDocument, options)
}
export function useProcessTemplatesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ProcessTemplatesQuery, ProcessTemplatesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ProcessTemplatesQuery, ProcessTemplatesQueryVariables>(
    ProcessTemplatesDocument,
    options,
  )
}
export type ProcessTemplatesQueryHookResult = ReturnType<typeof useProcessTemplatesQuery>
export type ProcessTemplatesLazyQueryHookResult = ReturnType<typeof useProcessTemplatesLazyQuery>
export type ProcessTemplatesSuspenseQueryHookResult = ReturnType<typeof useProcessTemplatesSuspenseQuery>
export type ProcessTemplatesQueryResult = Apollo.QueryResult<ProcessTemplatesQuery, ProcessTemplatesQueryVariables>
