import { createSelector, createSlice } from '@reduxjs/toolkit'

export type DrawerState = {
  show: boolean
}

export const initialState: DrawerState = {
  show: false,
}

export const drawerSlice = createSlice({
  name: 'layout/drawer',
  initialState,
  reducers: {
    toggleDrawer: (state) => {
      state.show = !state.show
    },
  },
})

export const selectDrawer = createSelector(
  (state: { layout: { drawer: DrawerState } }) => state.layout.drawer,
  (drawer) => drawer,
)

export const { toggleDrawer } = drawerSlice.actions

export default drawerSlice.reducer
