import React from 'react'
import appConfig from '../../../../config/app'
import { REDIRECT_URL_KEY } from '../../../../constants'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import useAuth from '../../../../hooks/useAuth'

const { unAuthenticatedEntryPath, onboardingEntryPath } = appConfig

const ProtectedRoute = () => {
  const { authenticated, user } = useAuth()

  const location = useLocation()

  const onboarding = !user?.tenants.length || !user?.programs.length

  if (!authenticated) {
    return <Navigate to={`${unAuthenticatedEntryPath}?${REDIRECT_URL_KEY}=${location.pathname}`} replace />
  } else if (onboarding) {
    return <Navigate to={onboardingEntryPath} replace />
  }

  return <Outlet />
}

export default ProtectedRoute
