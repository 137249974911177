import React from 'react'
import { Brand } from '../../../ui'
import appConfig from '../../../../config/app'

export type SidePeekBrandProps = {
  collapsed?: boolean
}

const SidePeekBrand = ({ collapsed }: SidePeekBrandProps) => {
  const containerPaddingX = collapsed ? 'px-[12px]' : 'px-[24px]'
  const opacity = collapsed ? 'opacity-0' : 'opacity-50'
  const textWidth = collapsed ? 'w-0' : 'w-[98px]'
  const textMarginRight = collapsed ? 'mr-0' : 'mr-[8px]'
  const wordmarkWidth = collapsed ? 'w-0' : 'w-[40px]'
  const duration = 'duration-[150ms]'

  const handleClick = () => {
    window.open(appConfig.productSite, '_blank')
  }

  return (
    <div
      onClick={handleClick}
      className={`transition-all ${duration} ${containerPaddingX} py-[16px] cursor-pointer flex items-center`}>
      <div
        className={`transition-all ${duration} ${textMarginRight} ${textWidth} ${opacity} overflow-hidden whitespace-nowrap body-semibold-xs text-gray uppercase tracking-[2.4px]`}>
        Powered By
      </div>
      <Brand type={'streamline'} mode={'light'} className={`w-[40px] h-[34px]`} />
      <img
        alt={'TMS Wordmark'}
        src={`${appConfig.issuePath}/img/logo/wordmark-side-peek.png`}
        className={`transition-all ${duration} ${opacity} ${wordmarkWidth} h-[34px]`}
      />
    </div>
  )
}

export default SidePeekBrand
