import React from 'react'
import { ForgotPassword, ResetPassword } from '../dummyComponents'
import { SignIn, SignUp } from '../../views/auth'
import { RouteConfig } from './index'

const publicRoutes: RouteConfig[] = [
  {
    title: 'Sign In',
    key: 'signIn',
    path: '/sign-in',
    layout: {
      side: true,
    },
    component: <SignIn />,
  },
  {
    title: 'Forgot Password',
    key: 'forgotPassword',
    path: '/forgot-password',
    component: <ForgotPassword />,
  },
  {
    title: 'Reset Password',
    key: 'resetPassword',
    path: '/reset-password',
    component: <ResetPassword />,
  },
  {
    title: 'Sign Up',
    key: 'signUp',
    path: '/sign-up',
    component: <SignUp />,
  },
]

export default publicRoutes
