import React, { useState, useEffect, FC } from 'react'
import { Indicator } from '../index'

interface SwitcherProps {
  checked?: boolean
  color?: string
  defaultChecked?: boolean
  disabled?: boolean
  isLoading?: boolean
  labelRef?: React.RefObject<HTMLLabelElement>
  name?: string
  onChange?: (checked: boolean) => void
  readOnly?: boolean
}

const Switcher: FC<SwitcherProps> = ({
  checked,
  color = 'bg-breakerBay-400',
  defaultChecked,
  disabled = false,
  isLoading = false,
  labelRef,
  name,
  onChange,
  readOnly = false,
}) => {
  const [internalChecked, setInternalChecked] = useState(defaultChecked ?? false)
  const isControlled = checked !== undefined

  useEffect(() => {
    if (isControlled) {
      setInternalChecked(checked!)
    }
  }, [checked, isControlled])

  const handleChange = () => {
    if (readOnly) {
      return
    }
    if (!isControlled) {
      setInternalChecked(!internalChecked)
    }
    onChange?.(!internalChecked)
  }

  const switchColor = (isControlled ? checked : internalChecked) ? color : 'bg-gray-400'
  const knobOrLoader = isLoading ? (
    <div
      className={`-mt-5 ml-1 transform ${
        isControlled ? (checked ? 'translate-x-4' : '') : internalChecked ? 'translate-x-4' : ''
      } transition-transform ease-in-out duration-300`}>
      <Indicator isSpinning={true} size={15} color={'white'} />
    </div>
  ) : (
    <span
      className={`absolute block w-5 h-5 -mt-[22px] ml-[2px] transform ${
        isControlled ? (checked ? 'translate-x-[16px]' : '') : internalChecked ? 'translate-x-[16px]' : ''
      } bg-white rounded-full shadow transition-all ease-in-out duration-300`}></span>
  )

  const disabledStyle = disabled ? 'opacity-50' : ''

  return (
    <label ref={labelRef} className={`inline-flex items-center cursor-pointer ${disabledStyle}`}>
      <span className={'relative'}>
        <span className={`block w-10 h-6 ${switchColor} rounded-full transition-colors duration-300`}></span>
        {knobOrLoader}
      </span>
      <input
        type={'checkbox'}
        className={'hidden'}
        name={name}
        checked={isControlled ? checked : internalChecked}
        onChange={handleChange}
        disabled={disabled}
        readOnly={readOnly}
      />
    </label>
  )
}

export default Switcher
