import React, { MouseEvent } from 'react'
import StatusIcon from '../../../../../ui/StatusIcon'
import getTimeLapsedString from '../../../../../../utils/getTimeLapsedString'
import { NotificationType } from '../../../../../../graphql'
import { StatusIconProps } from '../../../../../ui/StatusIcon/StatusIcon'

export type NotificationDropdownItemProps = {
  prefix?: string | null
  text?: string | null
  onPrefixClick?: (e: MouseEvent<HTMLSpanElement>) => void
  textHtml?: string | null
  timestamp?: Date
  unread?: boolean
  onClick?: (e: MouseEvent<HTMLDivElement>) => void
  type?: NotificationType | StatusIconProps['type']
  customIcon?: React.ReactNode
}
const NotificationDropdownItem = ({
  prefix,
  text,
  onPrefixClick,
  timestamp,
  unread = true,
  onClick = () => {},
  customIcon,
  type = NotificationType.Info,
  textHtml,
}: NotificationDropdownItemProps) => {
  const handlePrefixClick = (e: React.MouseEvent<HTMLSpanElement>) => {
    if (onPrefixClick) {
      e.stopPropagation()
      onPrefixClick(e)
    }
  }

  let prefixClass = 'w-fit font-bold'
  if (onPrefixClick) {
    prefixClass += ' hover:underline'
  }

  const maxTextLength = prefix ? 89 - prefix.length : 89
  if (text && text.length >= maxTextLength) {
    text = text.slice(0, maxTextLength - 3) + '...'
  }

  return (
    <div onClick={onClick} className={'flex flex-wrap w-full w-[449px] py-[3px] cursor-pointer'}>
      <div className={'flex justify-center items-center mr-[15px] w-[50px] h-[50px]'}>
        {customIcon || <StatusIcon type={type.toLowerCase() as StatusIconProps['type']} />}
      </div>
      <div className={'flex flex-col flex-1 mr-[20px]'}>
        {textHtml ? (
          <div className={''} dangerouslySetInnerHTML={{ __html: textHtml }} />
        ) : (
          <div>
            <span className={prefixClass} onClick={handlePrefixClick}>
              {prefix}
            </span>{' '}
            {text}
          </div>
        )}
        {timestamp && (
          <div className={'mt-[2px] body-xs text-secondary text-gray-dark font-semibold'}>
            {getTimeLapsedString(timestamp)}
          </div>
        )}
      </div>
      {unread && <div className={'self-center w-[8px] h-[8px] bg-blue rounded-full'} />}
    </div>
  )
}

export default NotificationDropdownItem
