import React from 'react'
import { Dropdown } from '../../../../ui'
import { ProgramDropdownButton, ProgramDropdownItem } from './index'
import { SignInFieldsFragmentDoc, ProgramFieldsFragment, useProgramsQuery } from '../../../../../graphql'
import useAuth from '../../../../../hooks/useAuth'
import { useApolloClient } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'
import { selectSidePeek, setShowSidePeek } from '../../../../../store/layout/sidePeekSlice'

const ProgramDropdown = () => {
  const { data } = useProgramsQuery()
  const { user } = useAuth()
  const client = useApolloClient()
  const dispatch = useDispatch()

  const { collapsed } = useSelector(selectSidePeek)

  const programs: ProgramFieldsFragment[] = data?.programs.list || []
  const selectedProgram =
    programs.find((p) => p.id === user?.viewingProgramId) ||
    programs.find((p) => p.id === user?.lastViewedProgramId) ||
    programs[0]

  const tenants: { id: string; name: string }[] = []
  programs.forEach((program) => {
    if (!tenants.find((t) => t.id === program.tenant.id)) {
      tenants.push(program.tenant)
    }
  })

  const handleClick = (program: ProgramFieldsFragment) => {
    // Wait a split second before closing the side peek (gives page time to load and smooths out the transition experience)
    setTimeout(() => {
      dispatch(setShowSidePeek(false))
    }, 350)
    client.cache.writeFragment({
      data: {
        __typename: 'SignInSuccess',
        user: {
          ...user,
          viewingProgramId: program.id,
        },
      },
      id: 'Auth:{}',
      fragment: SignInFieldsFragmentDoc,
    })
  }

  return programs.length ? (
    <Dropdown
      className={'w-full'}
      toggleClassName={'w-full'}
      delayCloseOnSelect={true}
      closeDelayDuration={250}
      renderToggle={<ProgramDropdownButton selectedProgram={selectedProgram} collapsed={collapsed} />}
      menuClass={'translate-x-[17px] translate-y-[-20px]'}>
      {tenants.map((tenant) => {
        return (
          <>
            <Dropdown.Group title={tenant.name} className={'mt-[15px] mb-[10px]'} />
            {programs
              .filter((p) => p.tenant.id === tenant.id)
              .map((program) => {
                return (
                  <Dropdown.Item
                    onClick={() => handleClick(program)}
                    key={program.id}
                    eventKey={`program-dropdown-item-${program.id}`}
                    render={<ProgramDropdownItem program={program} selected={selectedProgram.id === program.id} />}
                  />
                )
              })}
          </>
        )
      })}
    </Dropdown>
  ) : null
}

export default ProgramDropdown
