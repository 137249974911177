import { Badge, Icon } from '../../../../../ui'
import React, { MouseEvent } from 'react'

export type NotificationDropdownButtonProps = {
  unreadCount?: number
  onClick?: (event: MouseEvent<HTMLDivElement>, value: boolean) => void
  selected?: boolean
}

const NotificationDropdownButton = ({ unreadCount }: NotificationDropdownButtonProps) => {
  return (
    <Badge position={'top-right'} content={unreadCount} maxCount={99} hideEmptyDot={true}>
      <Icon name={'bell'} />
    </Badge>
  )
}

export default NotificationDropdownButton
