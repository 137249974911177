import React from 'react'
import { Alert, Button, Form, Input } from '../../../components/ui'
import {
  OnboardingFlow,
  TenantType,
  TenantUserRole,
  useCreateProgramMutation,
  useProcessTemplatesQuery,
  useRegisterTenantMutation,
} from '../../../graphql'
import { Field, Formik } from 'formik'
import { AnimatePresence } from 'framer-motion'
import * as Yup from 'yup'
import useAuth from '../../../hooks/useAuth'

export type SetupProgramStepProps = {
  onClick: (step: 'organization' | 'program') => void
}

const NewProgramForm = () => {
  const { user, token, set: setAuth } = useAuth()

  const [createProgram, { data, loading }] = useCreateProgramMutation({
    onCompleted: ({ createProgram }) => {
      if (createProgram.__typename === 'CreateProgramSuccess') {
        if (user) {
          if (token && user) {
            setAuth({
              token,
              user: {
                ...user,
                viewingProgramId: createProgram.program.id,
                programs: user.programs.length
                  ? [
                      ...user.programs,
                      {
                        program: createProgram.program,
                        __typename: 'ProgramUser',
                      },
                    ]
                  : [
                      {
                        program: createProgram.program,
                        __typename: 'ProgramUser',
                      },
                    ],
              },
            })
          }
        }
      }
    },
  })

  const [registerTenant] = useRegisterTenantMutation({
    refetchQueries: ['Me'],
    awaitRefetchQueries: true,
    onCompleted: ({ registerTenant }) => {
      if (registerTenant.__typename === 'RegisterTenantSuccess') {
        if (token && user) {
          setAuth({
            token,
            user: {
              ...user,
              tenants: user.tenants.length
                ? [
                    ...user.tenants,
                    {
                      tenant: registerTenant.tenant,
                      role: TenantUserRole.Owner,
                    },
                  ]
                : [
                    {
                      tenant: registerTenant.tenant,
                      role: TenantUserRole.Owner,
                    },
                  ],
            },
          })
        }
      }
    },
  })

  const { data: templateData } = useProcessTemplatesQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      where: {
        OR: [
          {
            tenant: {
              id: {
                equals: user?.tenants[0]?.tenant?.id || '',
              },
            },
          },
          {
            tenant: null,
          },
        ],
      },
    },
  })

  const templates = templateData?.processTemplates.list || []

  const errorMessage = data?.createProgram.__typename === 'CreateProgramError' ? data?.createProgram?.message : null

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
  })

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        name: '',
      }}
      onSubmit={async (data, { setSubmitting }) => {
        if (user?.tenants && user.tenants.length > 0 && user.tenants[0]?.tenant?.id) {
          setSubmitting(true)
          await createProgram({
            variables: {
              data: {
                name: data.name,
                tenantId: user.tenants[0].tenant.id,
                processTemplateId: templates[0]?.id || '',
              },
            },
          })
          setSubmitting(false)
        } else if (user?.onboardingFlow === OnboardingFlow.Personal) {
          setSubmitting(true)
          const tenant = await registerTenant({
            variables: {
              data: {
                name: user.person.firstName + "'s TMS",
                type: TenantType.Person,
              },
            },
          })
          if (tenant.data?.registerTenant.__typename === 'RegisterTenantSuccess') {
            await createProgram({
              variables: {
                data: {
                  name: data.name,
                  tenantId: tenant.data?.registerTenant.tenant.id || '',
                  processTemplateId: templates[0]?.id || '',
                },
              },
            })
          }
          setSubmitting(false)
        }
      }}>
      {({ errors, touched }) => (
        <div className="w-full max-w-[319px] self-center justify-self-center">
          <Form.Container>
            <div>
              <h2>New program</h2>
              <p className={'body-md'}>Create your first program.</p>
            </div>
            <Form.Item
              label={"What is your program's name?"}
              size={'sm'}
              errorMessage={touched.name && errors.name ? errors.name : undefined}
              invalid={touched.name && !!errors.name}>
              <Field size={'sm'} name={'name'} placeholder={'e.g. Discipleship'} component={Input} />
            </Form.Item>
            <AnimatePresence>{errorMessage && <Alert type={'danger'} label={errorMessage} />}</AnimatePresence>
            <div className={'flex justify-center'}>
              <Button type={'submit'} size={'sm'} label={'Continue'} loading={loading} block variant={'solid'} />
            </div>
          </Form.Container>
        </div>
      )}
    </Formik>
  )
}

export default NewProgramForm
