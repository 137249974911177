import React, { FC, PropsWithChildren } from 'react'
import SidePeekItem from './SidePeekItem'
import { useDispatch, useSelector } from 'react-redux'
import { selectSidePeek, setShowSidePeek } from '../../../../store/layout/sidePeekSlice'
import { ProgramDropdown } from './ProgramDropdown'
import SidePeekBrand from './SidePeekBrand'
import { adminNavigationItems, apprenticeNavigationItems, leaderNavigationItems } from '../../../../config/navigation'
import useAuth from '../../../../hooks/useAuth'
import { ViewAs } from '../../../../graphql'
import { useWindowSize } from '../../../../hooks/useWindowSize'
import { AnimatePresence, motion } from 'framer-motion'
import useLayout from '../../../../hooks/useLayout'

const SidePeek: FC<PropsWithChildren> = ({ children }) => {
  const { collapsed } = useSelector(selectSidePeek)

  const {
    sidePeek: { show },
  } = useLayout()

  const dispatch = useDispatch()

  const { width } = useWindowSize()

  const mobile = width < 768

  console.log(width)
  console.log(mobile)

  const { user } = useAuth()

  const items =
    user?.viewingAs === ViewAs.Admin
      ? adminNavigationItems
      : user?.viewingAs === ViewAs.Leader
      ? leaderNavigationItems
      : apprenticeNavigationItems

  const duration = 'duration-[150ms]'
  const containerWidth = mobile ? (show ? 'w-[260px]' : 'w-0') : collapsed ? 'w-[64px]' : 'w-[260px]'

  return (
    <div className={'flex h-screen'}>
      <div
        className={`${
          mobile ? 'overflow-hidden' : ''
        } fixed z-10 h-full md:static transition-all ${duration} ${containerWidth} bg-gray-900 flex flex-col`}>
        <div className={'mb-[25px]'}>
          <ProgramDropdown />
        </div>
        <div className={'flex-1'}>
          {items.map((item) => (
            <SidePeekItem {...item} routeKey={item.key} collapsed={collapsed} />
          ))}
        </div>
        <SidePeekBrand collapsed={collapsed} />
      </div>
      <div className={'flex flex-1 flex-col'}>{children}</div>
      <AnimatePresence>
        {mobile && show && (
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <div
              onClick={() => dispatch(setShowSidePeek(false))}
              className={'fixed top-0 bottom-0 right-0 left-0 bg-gray-900 opacity-30'}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default SidePeek
