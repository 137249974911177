import React from 'react'
import { useGuestInvitationsQuery } from '../../../graphql'
import GuestInvitations from '../GuestInvitations'
import { Indicator } from '../../../components/ui'
import { Navigate } from 'react-router-dom'
import useAuth from '../../../hooks/useAuth'

const PersonalOnboarding = () => {
  const { user } = useAuth()

  const { data: guestInvitationsData, loading: loadingGuestInvitations } = useGuestInvitationsQuery({
    fetchPolicy: 'cache-and-network',
  })

  const invitations = guestInvitationsData?.invitations?.list || []

  // If has programs, go to the dashboard
  // Else if has member invitations, show the member invite list
  // Else if has guest invitations, show the guest invite list
  // Else if has neighter, show the program setup step

  const hasInvitations = invitations.length > 0
  const hasOrganizations = !!user?.tenants.length
  const hasPrograms = !!user?.programs.length

  const loading = loadingGuestInvitations && !guestInvitationsData

  // We can assume that hasOrganizations and hasPrograms is never true since that is handled by the layout route components
  return loading ? (
    // Show loading indicator while we look for existing invitations for organizational onboarding
    <div className={'h-full flex justify-center items-center'}>
      <Indicator size={30} indicator={'loader'} isSpinning />
    </div>
  ) : // If the user has organizations and programs then they are good to go
  hasOrganizations && hasPrograms ? (
    <Navigate to={'/dashboard'} />
  ) : // If the user has guest invitations and isn't yet part of an organization
    // then show the guest invitation list, so they can optionally join an existing program
    hasInvitations && !hasOrganizations ? (
    <GuestInvitations invitations={invitations} />
  ) : (
    // If the user isn't part of a program or organization and has no invitations
    // then take them directly to the 'create new program' page
    <Navigate to={'/new-program'} />
  )
}

export default PersonalOnboarding
