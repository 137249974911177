import React, { useContext, FC, ChangeEvent, InputHTMLAttributes } from 'react'
import RadioGroupContext from './Context'

export interface RadioProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type' | 'onChange'> {
  checked?: boolean
  defaultChecked?: boolean
  disabled?: boolean
  id?: string
  labelRef?: React.RefObject<HTMLLabelElement>
  name?: string
  onChange?: (value: any, event: ChangeEvent<HTMLInputElement>) => void
  value: any
  color?: string
}

const Radio: FC<RadioProps> = ({
  checked,
  defaultChecked,
  disabled,
  id,
  labelRef,
  name,
  onChange,
  value,
  color = 'breakerBay-400',
  children,
  ...rest
}) => {
  const context = useContext(RadioGroupContext)

  const isChecked = context ? context.value === value : checked ?? defaultChecked

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (context && context.onChange) {
      context.onChange(value, e)
    } else if (onChange) {
      onChange(value, e)
    }
  }

  const checkedBgClass = `bg-${color}`
  const checkedBorderClass = `border-${color} border-2`

  return (
    <label
      ref={labelRef}
      className={`inline-flex items-center gap-2 ${disabled ? 'opacity-50' : 'cursor-pointer'}`}
      htmlFor={id}>
      <div
        className={`w-4 h-4 rounded-full border flex items-center justify-center ${
          isChecked ? `${checkedBgClass} ${checkedBorderClass}` : 'border-2 border-gray-light'
        }`}
        style={disabled ? { cursor: 'default' } : {}}>
        {isChecked && <span className="block bg-white w-1 h-1 rounded-full"></span>}
      </div>
      <input
        type="radio"
        id={id}
        name={name}
        className="sr-only"
        checked={isChecked}
        onChange={handleChange}
        disabled={disabled}
        value={value}
        {...rest}
      />
      <span className={'body-lg'}>{name}</span>
      {children}
    </label>
  )
}

export default Radio
