import React, { useMemo } from 'react'
import NotificationDropdownFooter from './NotificationDropdownFooter'
import NotificationDropdownHeader from './NotificationDropdownHeader'
import NotificationDropdownItem from './NotificationDropdownItem'
import { Avatar, Dropdown } from '../../../../../ui'
import {
  NotificationsDocument,
  useNotificationsQuery,
  useUpsertNotificationsMutation,
  ViewAs,
} from '../../../../../../graphql'
import NotificationDropdownButton from './NotificationDropdownButton'
import useAuth from '../../../../../../hooks/useAuth'

type NotificationDropdownProps = {
  onOpen?: () => void
  onClose?: () => void
}

const NotificationDropdown = ({ onOpen, onClose }: NotificationDropdownProps) => {
  const { user } = useAuth()
  const viewingAs = user?.viewingAs || ViewAs.Apprentice

  const { data } = useNotificationsQuery({
    variables: {
      data: {
        programId: '1',
        viewingAs,
      },
    },
  })

  const [markAllRead] = useUpsertNotificationsMutation({
    variables: {
      data: {
        programId: '1',
        viewingAs: ViewAs.Admin,
        notifications:
          data?.notifications.map((notification) => ({
            id: notification.id,
            viewed: true,
          })) || [],
      },
    },
    update: (cache, { data }) => {
      cache.writeQuery({
        query: NotificationsDocument,
        variables: {
          data: {
            programId: '1',
            viewingAs: ViewAs.Admin,
          },
        },
        data: {
          notifications: data?.upsertNotifications ? [...data.upsertNotifications] : [],
        },
      })
    },
  })

  const unreadCount = useMemo(() => data?.notifications.filter((notification) => !notification.viewed).length, [data])

  return (
    <Dropdown
      onOpen={onOpen}
      onClose={onClose}
      menuClass={'max-w-[489px]'}
      renderHeader={<NotificationDropdownHeader onMarkAllRead={() => markAllRead()} />}
      renderToggle={<NotificationDropdownButton unreadCount={unreadCount} />}
      placement={'bottom-end'}>
      {data &&
        data.notifications.map((notification, index) => {
          const person = notification.person
          const prefix = person ? person.firstName + ' ' + person.lastName : undefined
          return (
            <Dropdown.Item
              render={
                <NotificationDropdownItem
                  key={index}
                  onClick={() => alert('Clicked on notification!')}
                  timestamp={new Date(notification.createdAt)}
                  unread={!notification.viewed}
                  prefix={prefix}
                  text={notification.text}
                  textHtml={undefined}
                  onPrefixClick={() => alert(`Clicked on ${prefix}!`)}
                  type={notification.type}
                  customIcon={person ? <Avatar src={'' /*notification.person?.avatar?.src*/} size={40} /> : undefined}
                />
              }
            />
          )
        })}
      <NotificationDropdownFooter onClickViewAll={() => alert('clicked view all!')} />
    </Dropdown>
  )
}

export default NotificationDropdown
