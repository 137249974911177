import React from 'react'
import Avatar from '../../../../../ui/Avatar'
import { UserFieldsFragment } from '../../../../../../graphql'

export type UserDropdownButtonProps = {
  user?: UserFieldsFragment
}

const UserDropdownButton = ({ user }: UserDropdownButtonProps) => {
  return (
    <Avatar
      className={'transition-opacity duration-350 hover:opacity-80 hover:opacity-80'}
      shape="circle"
      size={30}
      name={user?.person.name}
    />
  )
}

export default UserDropdownButton
