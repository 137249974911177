import React from 'react'
import { Connections, MyJourney, Organization, People, Phases, Steps } from '../dummyComponents'
import Dashboard from '../../views/dashboard'
import { RouteConfig } from './index'
import { Role } from '../../graphql'

const protectedRoutes: RouteConfig[] = [
  {
    title: 'Dashboard',
    key: 'dashboard',
    path: '/dashboard',
    component: <Dashboard />,
    authority: [Role.Apprentice, Role.Leader],
  },
  {
    title: 'People',
    key: 'people',
    path: '/people',
    component: <People />,
  },
  {
    title: 'Connections',
    key: 'connections',
    path: '/connections',
    component: <Connections />,
  },
  {
    title: 'Steps',
    key: 'steps',
    path: '/steps',
    component: <Steps />,
  },
  {
    title: 'Phases',
    key: 'phases',
    path: '/phases',
    component: <Phases />,
  },
  {
    title: 'My Journey',
    key: 'myJourney',
    path: '/my-journey',
    component: <MyJourney />,
  },
  {
    title: 'Organization',
    key: 'organization',
    path: '/organization',
    component: <Organization />,
  },
]

export default protectedRoutes
