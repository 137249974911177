import React, { ReactNode, MouseEvent } from 'react'
import UserDropdownComponent from './UserDropdown'
import NotificationDropdownComponent from './NotificationDropdown'
import UtilityToggleComponent from './UtilityToggle'
import SidePeekToggleComponent from './SidePeekToggle'
import { useDispatch } from 'react-redux'
import { setShowSidePeek, toggleSidePeek } from '../../../../../store/layout/sidePeekSlice'
import useAuth from '../../../../../hooks/useAuth'
import useLayout from '../../../../../hooks/useLayout'
import { toggleDrawer } from '../../../../../store/layout/drawerSlice'
import classNames from 'classnames'
import { useWindowSize } from '../../../../../hooks/useWindowSize'
import { UserFieldsFragment } from '../../../../../graphql'

type HeaderItemProps = {
  selected?: boolean
  children?: ReactNode
  onClick?: (event: MouseEvent<HTMLDivElement>, value: boolean) => void
  className?: string
}

const HeaderItem = ({ children, onClick, className, selected = false }: HeaderItemProps) => {
  const handleOnClick = (e: MouseEvent<HTMLDivElement>) => {
    onClick?.(e, !selected)
  }
  return (
    <div
      onClick={handleOnClick}
      className={classNames(
        `${
          selected ? 'bg-gray-light' : 'bg-white hover:bg-gray-100'
        } rounded-[5px] p-[4px] cursor-pointer transition duration-150`,
        className,
      )}>
      {children}
    </div>
  )
}

const UserDropdown = () => {
  const { user } = useAuth()

  return (
    <HeaderItem className={'hover:bg-white'}>
      <UserDropdownComponent user={user as UserFieldsFragment} />
    </HeaderItem>
  )
}

const NotificationDropdown = () => {
  const [open, setOpen] = React.useState(false)

  return (
    <HeaderItem selected={open}>
      <NotificationDropdownComponent onOpen={() => setOpen(true)} onClose={() => setOpen(false)} />
    </HeaderItem>
  )
}

const UtilityToggle = () => {
  const dispatch = useDispatch()
  const {
    drawer: { show },
  } = useLayout()

  return (
    <HeaderItem selected={show} onClick={() => dispatch(toggleDrawer())}>
      <UtilityToggleComponent />
    </HeaderItem>
  )
}

const SidePeekToggle = () => {
  const dispatch = useDispatch()
  const { width } = useWindowSize()
  const {
    sidePeek: { collapsed },
  } = useLayout()

  const handleOnClick = () => {
    if (width < 768) {
      dispatch(setShowSidePeek(true))
    } else {
      dispatch(toggleSidePeek())
    }
  }

  return (
    <HeaderItem className={'hover:bg-white'} onClick={handleOnClick}>
      <SidePeekToggleComponent collapsed={collapsed} />
    </HeaderItem>
  )
}

HeaderItem.SidePeekToggle = SidePeekToggle
HeaderItem.UtilityToggle = UtilityToggle
HeaderItem.NotificationDropdown = NotificationDropdown
HeaderItem.UserDropdown = UserDropdown

export default HeaderItem
