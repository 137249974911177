import {
  AuthFieldsFragment,
  SignInInput,
  SignInMutation,
  ReceiveConfirmationMutation,
  useSignInMutation,
  ViewAs,
} from '../graphql'
import { REDIRECT_URL_KEY } from '../constants'
import appConfig from '../config/app'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import useParams from './useParams'
import { persistor } from '../services/ApolloService'
import { ApolloError, FetchResult } from '@apollo/client'
import { setAuth } from './useAuth'

type SignInMutationResult = FetchResult<SignInMutation>
type ReceiveConfirmationMutationResult = FetchResult<ReceiveConfirmationMutation>

type HandleSignInParams = {
  params: URLSearchParams
  navigate: NavigateFunction
  persist?: boolean
}

export const handleAuthRequest = async <Result extends SignInMutationResult | ReceiveConfirmationMutationResult>(
  request: Promise<Result>,
  { params, navigate, persist = false }: HandleSignInParams,
): Promise<Result> => {
  if (!persist) {
    persistor.pause()
  }

  let result: Result
  try {
    result = await request

    if (result.data) {
      let auth: AuthFieldsFragment | undefined

      if ('signIn' in result.data) {
        if (result.data.signIn.__typename === 'SignInSuccess') {
          auth = result.data.signIn.auth
        }
      } else if ('receiveConfirmation' in result.data) {
        if (result.data.receiveConfirmation.__typename === 'ReceiveConfirmationSuccess') {
          auth = result.data.receiveConfirmation.auth
        }
      }

      if (auth) {
        if (auth.__typename === 'UserAuth') {
          //const lastViewedAs = Role.Admin // resp.data.login.me.lastViewedAs
          //let initialViewAs = Role.Admin
          // Todo: fix the default last viewed as logic when signing in
          //if (resp.data?.login.user.roles.includes(Role.Admin)) {
          //  initialViewAs = Role.Admin
          //} else if (resp.data?.login.user.roles.includes(Role.Leader)) {
          //  initialViewAs = Role.Leader
          //}
          auth.user.viewingAs = ViewAs.Admin
          setAuth(auth)
          //localStorage.setItem('viewingAs', lastViewedAs ? lastViewedAs : initialViewAs)
          const redirectUrl = params.get(REDIRECT_URL_KEY)
          navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath)
        } else {
          setAuth(auth)
        }
      }
    }

    if (!persist) {
      setTimeout(() => persistor.resume(), 1500)
    }

    return result
  } catch (error) {
    if (!persist) {
      setTimeout(() => persistor.resume(), 1500)
    }
    throw error
  }
}

function useSignIn() {
  const navigate = useNavigate()
  const params = useParams()

  const [login] = useSignInMutation({
    update: (cache, { data }) => {
      if (data?.signIn.__typename === 'SignInSuccess') {
        //const lastViewedAs = Role.Admin // data?.login.user.lastViewedAs
        //let initialViewAs = Role.Admin
        //if (data?.login.user.roles.includes(Role.Admin)) {
        //  initialViewAs = Role.Admin
        //} else if (data?.login.user.roles.includes(Role.Leader)) {
        //  initialViewAs = Role.Leader
        //}
        //const viewingAs = lastViewedAs ? lastViewedAs : initialViewAs
        //const { auth } = data.login
        //auth.user.viewingAs = ViewAs.Admin
        /*cache.writeQuery({
          query: MeDocument,
          data: {
            user: auth.user,
          },
        })

        setAuth(data.login.auth, cache)*/
      }
    },
  })

  return async (data: (SignInInput | { token: string }) & { staySignedIn: boolean }) => {
    let message = ''
    let status: 'failed' | 'success' | 'invalid' = 'success'
    try {
      const { staySignedIn: persist, ...rest } = data

      const request = login({
        variables:
          'email' in rest
            ? {
                data: rest,
              }
            : undefined,
        context:
          'token' in rest
            ? {
                headers: {
                  authorization: `Basic ${rest.token}`,
                },
              }
            : undefined,
      })
      const result = await handleAuthRequest(request, { params, navigate, persist })
      if (result.data?.signIn.__typename === 'SignInError') {
        message = 'Ivalid email or password' // resp.data.login.message
        status = 'failed'
      }
    } catch (error) {
      message = 'Something went wrong'
      status = 'failed'
      if (error instanceof ApolloError) {
        if (error.graphQLErrors.length > 0) {
          const gqlError = error.graphQLErrors[0]
          message = gqlError.message
          if (gqlError.extensions?.code === 'FORBIDDEN') {
            status = 'invalid'
          }
        }
      } else if (error instanceof Error) {
        message = error.message
      }
    }
    return {
      status,
      message,
    }
  }
}

export default useSignIn
