import React, { forwardRef, useEffect, useRef, useState, MouseEvent } from 'react'
import { Input } from '../../ui'
import { useDispatch } from 'react-redux'
import { setSearch } from '../../../store/data/tableSlice'
import Icon from '../Icon'

type TableSearchProps = {
  onInputChange?: (value: string) => void
  tableName?: string
  value?: string
}

const TableSearch = forwardRef<HTMLInputElement, TableSearchProps>(function TableSearch(
  { onInputChange, tableName, value },
  ref,
) {
  const [searching, setSearching] = useState(false)
  const wrapperRef = useRef<HTMLDivElement>(null)

  const dispatch = useDispatch()

  const handleInputChange = (e: { target: { value: string } }) => {
    if (tableName) {
      dispatch(setSearch({ key: tableName, search: e.target.value }))
    }
    onInputChange?.(e.target.value)
  }

  const handleOnClickSearch = (e: MouseEvent) => {
    e.preventDefault()
    const input = wrapperRef?.current?.getElementsByTagName('input')[0]
    input?.focus()
    setSearching(true)
  }

  useEffect(() => {
    const handleCloseSearch = (e: any) => {
      if (!e.target.classList.contains('injected-svg')) {
        if (!value && searching && !wrapperRef?.current?.parentNode?.contains(e.target)) {
          setSearching(false)
        }
      }
    }
    document.addEventListener('click', handleCloseSearch)
    return () => {
      document.removeEventListener('click', handleCloseSearch)
    }
  }, [searching, value])

  return (
    <div ref={wrapperRef} onClick={handleOnClickSearch}>
      <Input
        ref={ref}
        size="xs"
        placeholder="Search All"
        prefix={<Icon className={'pointer-events-none left-0 z-[1] relative'} name={'search'} size={20} />}
        onChange={handleInputChange}
        wrapperName={`!right-0 z-[1] transition-all ease-in-out duration-500 ${searching ? '!w-[175px]' : '!w-[30px]'}`}
        className={`transition-all ease-in-out duration-500 p-0 ${
          searching ? ' opacity-1' : '!pl-7 opacity-0 cursor-pointer'
        }`}
      />
    </div>
  )
})

export default TableSearch
