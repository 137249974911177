import {
  AuthFieldsFragment,
  AuthFieldsFragmentDoc,
  ConfirmationAuthFieldsFragment,
  MeDocument,
  OnboardingFlow,
  UserFieldsFragment,
} from '../graphql'
import { ApolloCache, useFragment } from '@apollo/client'
import useSignIn from './useSignIn'
import useSignOut from './useSignOut'
import { cache as _cache } from '../services/ApolloService'

export const setAuth = (auth: AuthFieldsFragment | null, cache: ApolloCache<any> = _cache) => {
  try {
    if (auth?.__typename === 'UserAuth') {
      cache.writeQuery({
        query: MeDocument,
        data: {
          ...auth.user,
        },
      })
    }
    cache.writeFragment({
      data: auth,
      id: 'Auth:{}',
      fragment: AuthFieldsFragmentDoc,
      fragmentName: 'authFields',
    })
  } catch (e) {
    console.log(e)
  }
}

function useAuth() {
  const signIn = useSignIn()
  const signOut = useSignOut()

  const { data } = useFragment<AuthFieldsFragment>({
    fragment: AuthFieldsFragmentDoc,
    fragmentName: 'authFields',
    from: 'Auth:{}',
  })

  // Map the partial data user response to a full user response by providing default values for important missing fields
  const user =
    data?.__typename === 'UserAuth'
      ? ({
          ...data.user,
          tenants: data.user?.tenants || [],
          programs: data.user?.programs || [],
        } as UserFieldsFragment)
      : undefined

  return {
    authenticated: !!data.token && !!user,
    token: data.token,
    user,
    confirmation:
      data.__typename === 'ConfirmationAuth'
        ? (data.confirmation as ConfirmationAuthFieldsFragment['confirmation'])
        : undefined,
    signIn,
    signOut,
    set: setAuth,
    setOnboardingFlow: (flow: OnboardingFlow | null) => {
      if (data.token && user) {
        setAuth({
          __typename: 'UserAuth',
          token: data.token,
          user: {
            ...user,
            onboardingFlow: flow,
          },
        })
      } else {
        throw new Error('Cannot set onboarding flow without an authenticated user')
      }
    },
  }
}

export default useAuth
