import React, { ReactNode } from 'react'

type HeaderProps = {
  start?: ReactNode[]
  end?: ReactNode[]
}

const Header = ({ start, end }: HeaderProps) => {
  return (
    <>
      <div className={'h-[68px] md:hidden'}></div>
      <div
        className={
          'fixed h-[64px] pl-[12px] pr-[12px] sm:pl-[16px] sm:pr-[16px] md:sticky w-full bg-white md:pl-[36px] md:pr-[36px] flex items-center justify-between md:h-[68px] border-b-2 border-gray-900'
        }>
        <div className={'flex items-center gap-[32px]'}>{start?.map((component) => component)}</div>
        <div className={'flex flex-row-reverse items-center gap-[32px]'}>{end?.map((component) => component)}</div>
      </div>
    </>
  )
}

export default Header
