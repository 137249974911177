import { ApolloLink, createHttpLink, from } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { DecoratedClientProps } from './index'
import config from '../config/app'
import { REQUEST_HEADER_AUTH_KEY, TOKEN_TYPE } from '../constants/api'
import { setContext } from '@apollo/client/link/context'
import { AuthFieldsFragmentDoc } from '../graphql'

const { api } = config

export const decorateErrorLink: (props: DecoratedClientProps) => ApolloLink = ({ auth }) =>
  onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.map(async ({ message, locations, path, extensions }) => {
        console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
        if (extensions?.code === 'UNAUTHENTICATED') {
          await auth.signOut()
        }
      })
    if (networkError) console.log(`[Network error]: ${networkError}`)
  })

const decorateHttpLink: (props: DecoratedClientProps) => ApolloLink = () =>
  createHttpLink({
    uri: `${api.baseUrl}${api.graphQLPath}`,
  })

const decorateAuthLink: (props: DecoratedClientProps) => ApolloLink = () =>
  setContext((_, { headers, cache }) => {
    //const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME)
    //const persistData = deepParseJson(rawPersistData)

    const data = cache.diff({
      returnPartialData: true,
      id: 'Auth:{}',
      query: cache['getFragmentDoc'](AuthFieldsFragmentDoc, 'authFields'),
    })

    const accessToken = data?.result?.token

    if (accessToken) {
      return {
        headers: {
          ...headers,
          [REQUEST_HEADER_AUTH_KEY]: `${TOKEN_TYPE} ${accessToken}`,
        },
      }
    }

    return headers
  })

const decorateLink = (props: DecoratedClientProps) => {
  const errorLink = decorateErrorLink(props)
  const authLink = decorateAuthLink(props)
  const httpLink = decorateHttpLink(props)
  return from([errorLink, authLink, httpLink])
}

export default decorateLink
