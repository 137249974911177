import View from './components/View'
import Header from './components/Header'
import SidePeek from './components/SidePeek'
import React from 'react'
import HeaderItem from './components/Header/HeaderItem'
import Drawer from '../ui/Drawer'
import useLayout from '../../hooks/useLayout'
import { AdminUtilities } from './components/UtilityDrawer'

const AdminLayout = () => {
  const { UserDropdown, NotificationDropdown, UtilityToggle, SidePeekToggle } = HeaderItem
  const {
    drawer: { show },
  } = useLayout()

  return (
    <SidePeek>
      <Header start={[<SidePeekToggle />]} end={[<UserDropdown />, <NotificationDropdown />, <UtilityToggle />]} />
      <Drawer.Container>
        <Drawer.View>
          <View />
        </Drawer.View>
        <Drawer isOpen={show}>
          <AdminUtilities />
        </Drawer>
      </Drawer.Container>
    </SidePeek>
  )
}

export default AdminLayout
