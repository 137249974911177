import React, { cloneElement, ReactNode } from 'react'
import Icon from '../Icon'
import Button from '../Button'
import classNames from 'classnames'

type DropdownToggleProps = {
  title?: string
  renderToggle?: ReactNode
  toggleClassName?: string
  disabled?: boolean
  placement?:
    | 'top-start'
    | 'top-center'
    | 'top-end'
    | 'bottom-start'
    | 'bottom-center'
    | 'bottom-end'
    | 'middle-start-top'
    | 'middle-start-bottom'
    | 'middle-end-top'
    | 'middle-end-bottom'
  selected?: boolean
}

const DropdownToggle = React.forwardRef(
  (
    {
      title,
      renderToggle,
      toggleClassName,
      disabled,
      selected = false,
      placement = 'bottom-start',
    }: DropdownToggleProps,
    ref,
  ) => {
    if (renderToggle) {
      return (
        <div className={classNames('cursor-pointer ', toggleClassName)}>
          {cloneElement(renderToggle as React.ReactElement, { selected })}
        </div>
      )
    } else {
      let iconName: IconName = 'chevron-down'
      let alignment: 'right' | 'left' = 'right'
      if (placement && placement.includes('middle-start')) {
        iconName = 'chevron-right'
      } else if (placement && placement.includes('middle-end')) {
        iconName = 'chevron-left'
        alignment = 'left'
      } else if (placement && placement.includes('top')) {
        iconName = 'chevron-up'
      }
      const icon = <Icon name={iconName} />
      return (
        <Button type={'button'} label={title} size={'sm'} icon={icon} iconLocation={alignment} disabled={disabled} />
      )
    }
  },
)

export default DropdownToggle
