import React from 'react'
import {
  MemberInvitationFieldsFragment,
  MemberInvitationsDocument,
  OnboardingFlow,
  useAcceptInvitationMutation,
  useDeclineInvitationMutation,
} from '../../graphql'
import { Avatar, Button, Card, Icon, Tooltip } from '../../components/ui'
import useAuth from '../../hooks/useAuth'
import pluralize from 'pluralize'
import useToast from '../../hooks/useToast'
import { useNavigate } from 'react-router-dom'

type MemberInviteCardProps = {
  invitation: MemberInvitationFieldsFragment
}

/*
This component renders a single invitation card with decline and accept interactions.
Each card includes the tenant's name, member avatar, and the number of members.
*/
const MemberInviteCard = ({ invitation }: MemberInviteCardProps) => {
  // Since tenant may be undefined, we need to default the members array to an empty array.
  const members = invitation.tenant?.members || []
  const numberOfMembers = members.length
  // Since tenant may be undefined, we need to default the programs array to an empty array.
  const programs = invitation.tenant?.programs || []
  const numberOfPrograms = programs.length

  const showSeparator = numberOfMembers > 0 && numberOfPrograms > 0

  const toast = useToast()

  const { user } = useAuth()

  // Mutation to accept an invitation. The mutation refetches all invitations upon completion.
  const [acceptInvitation, { loading: loadingAccept }] = useAcceptInvitationMutation({
    refetchQueries: ['MemberInvitations', 'Me'],
    awaitRefetchQueries: true,
    variables: {
      where: {
        id: invitation.id,
      },
    },
    // Mutation complete - Checks for success or user error after mutation.
    onCompleted: ({ acceptInvitation }) => {
      if (acceptInvitation?.__typename === 'AcceptInvitationSuccess') {
        toast.push('Success', acceptInvitation.message, 'success')
      } else if (acceptInvitation?.__typename?.endsWith('Error')) {
        toast.push('Error', acceptInvitation.message, 'error')
      }
    },
    // Mutation error - Handles exception (graphql) errors from the mutation.
    onError: (error) => {
      toast.push('Error', 'An error occurred while accepting the invitation.', 'error')
    },
  })

  // Mutation to decline an invitation. The mutation refetches all invitations upon completion.
  const [declineInvitation, { loading: loadingDecline }] = useDeclineInvitationMutation({
    refetchQueries: [MemberInvitationsDocument],
    awaitRefetchQueries: true,
    variables: {
      where: {
        id: invitation.id,
      },
    },
    // Mutation complete - Checks for success or user error after mutation.
    onCompleted: ({ declineInvitation }) => {
      if (declineInvitation?.__typename === 'DeclineInvitationSuccess') {
        toast.push('Success', declineInvitation.message, 'success')
      } else if (declineInvitation?.__typename?.endsWith('Error')) {
        toast.push('Error', declineInvitation.message, 'error')
      }
    },
    // Mutation error - Handles exception (graphql) errors from the mutation.
    onError: (error) => {
      toast.push('Error', 'An error occurred while declining the invitation.', 'error')
    },
  })

  const handleAccept = async () => {
    await acceptInvitation()
    // Exception (graphql) errors are handled by the mutation's 'onError' event.
  }

  const handleDecline = async () => {
    await declineInvitation()
    // Exception (graphql) errors are handled by the mutation's 'onError' event.
  }

  // Rendering the invitation card with tenant information and action buttons
  return (
    <div className={'w-full'}>
      <Card
        headerIcon={<Icon name={'briefcase'} className={'translate-y-[-2.5px]'} />}
        header={invitation.tenant?.name}
        bodyClass={'flex flex-col gap-3'}
        headerDivider={true}
        footer={
          <div className={'flex justify-between w-full items-center'}>
            <Button
              loading={loadingDecline}
              onClick={handleDecline}
              size={'md'}
              iconLocation={'left'}
              label={'Decline'}
              variant={'plain'}></Button>
            <Button
              loading={loadingAccept}
              onClick={handleAccept}
              color={'breakerBay'}
              size={'md'}
              iconLocation={'right'}
              label={user?.onboardingFlow === OnboardingFlow.Organizational ? 'Join' : 'Accept'}
              variant={'plain'}
              icon={
                user?.onboardingFlow === OnboardingFlow.Organizational ? (
                  <Icon name={'arrow-right'} color={'breakerBay'} />
                ) : (
                  <Icon name={'check'} color={'breakerBay'} />
                )
              }></Button>
          </div>
        }
        headerClass={'text-lg font-semibold mb-[20px]'}
        footerClass={'text-blue-500 font-bold'}>
        <p>
          <Tooltip title={`${invitation.sender.name} | ${invitation.sender.email}`}>
            <b>{invitation.sender.firstName}</b>
          </Tooltip>{' '}
          has sent you an invite
        </p>
        <div className={'flex flex-col gap-2'}>
          <p className={'font-normal body-sm text-gray-dark'}>
            {numberOfMembers > 0 ? `${numberOfMembers} ${pluralize('Member', numberOfMembers)}` : ''}
            {showSeparator ? ' | ' : ''}
            {numberOfPrograms > 0 ? `${numberOfPrograms} ${pluralize('Program', numberOfPrograms)}` : ''}
          </p>
          <Avatar.Group chained maxCount={6} omittedAvatarTooltip omittedAvatarProps={{ size: 'sm' }}>
            {/* Iterating over members to show avatar in a group for each invitation */}
            {members.map((member) => (
              <Tooltip title={`${member.person.name} | ${member.person.email}`}>
                <Avatar
                  shape={'circle'}
                  size={'sm'}
                  src={member.person.profileImage?.url}
                  name={member.person.name}
                  key={`invitation:${invitation.id}-member:${member.id}`}
                />
              </Tooltip>
            ))}
          </Avatar.Group>
        </div>
      </Card>
    </div>
  )
}

type MemberInvitationsProps = {
  invitations: MemberInvitationFieldsFragment[]
  onContinue?: () => void
}

/*
This component renders a list of member invitation cards with decline and accept interactions.
The user may use these cards to accept or decline invitations to a tenant, or continue to the next step.
*/
const MemberInvitations = ({ invitations, onContinue }: MemberInvitationsProps) => {
  const { setOnboardingFlow } = useAuth()
  const navigate = useNavigate()
  const { user } = useAuth()

  let canContinue = false
  if (user?.onboardingFlow === OnboardingFlow.Organizational) {
    canContinue = user.tenants.length > 0
  } else {
    canContinue = true
  }

  const pageTitle = user?.onboardingFlow === OnboardingFlow.Personal ? 'Member invitations' : 'Setup your organization'
  const pageDescription =
    user?.onboardingFlow === OnboardingFlow.Personal
      ? 'You have been invited to join the following organizations. You can always come back to these later.'
      : 'You have invitations to existing organizations. Please select the one you wish to join or create a new one and visit these invitations later.'

  return (
    <div className={'pt-[100px] flex flex-col'}>
      <div className={'w-full max-w-[319px] p-[20px] self-center justify-self-center'}>
        <div className={'text-center'}>
          <h2>{pageTitle}</h2>
          <p className={'body-md'}>{pageDescription}</p>
          <div className="h-[44px]" />
        </div>
        <div className={'flex flex-col justify-center items-center gap-[20px]'}>
          {/* Iterating over invitations to render each as a Card with properties and methods */}
          {invitations.map((invitation) => {
            return <MemberInviteCard invitation={invitation} key={invitation.id} />
          })}
        </div>
        <div className="h-[20px]" />
        <div className={'flex flex-col justify-center gap-2'}>
          <Button
            onClick={() => {
              if (!canContinue) {
                navigate('/new-organization')
              } else {
                onContinue?.()
              }
            }}
            type={'submit'}
            size={'sm'}
            label={canContinue ? 'Continue' : 'Create new'}
            block
            variant={'solid'}
          />
          <Button
            onClick={() => {
              // Setting the selected onboarding flow option back to 'null' will automatically direct the user back to the 'ChooseOnboardingFlow' component.
              setOnboardingFlow(null)
            }}
            type={'submit'}
            size={'sm'}
            label={'Back'}
            block
            icon={<Icon name={'arrow-left'} />}
          />
        </div>
      </div>
    </div>
  )
}

export default MemberInvitations
