import useAuth from '../../hooks/useAuth'
import { Avatar, Button, Card, Icon, Indicator, Tooltip } from '../../components/ui'
import React from 'react'
import { ProgramFieldsFragment, useJoinProgramMutation, useProgramsQuery } from '../../graphql'
import useToast from '../../hooks/useToast'
import pluralize from 'pluralize'
import { useNavigate } from 'react-router-dom'

type ProgramCardProps = {
  program: ProgramFieldsFragment
}

const ProgramCard = ({ program }: ProgramCardProps) => {
  const people = program.users.map((programUser) => programUser.user)
  const numberOfPeople = people.length

  const toast = useToast()

  const [joinProgram, { loading }] = useJoinProgramMutation({
    refetchQueries: ['Programs', 'Me'],
    awaitRefetchQueries: true,
    variables: {
      where: {
        id: program.id,
      },
    },
    // Mutation complete - Checks for success or user error after mutation.
    onCompleted: ({ joinProgram }) => {
      if (joinProgram?.__typename === 'JoinProgramSuccess') {
        toast.push('Success', joinProgram.message, 'success')
      } else if (joinProgram?.__typename?.endsWith('Error')) {
        toast.push('Error', joinProgram.message, 'error')
      }
    },
    // Mutation error - Handles exception (graphql) errors from the mutation.
    onError: (error) => {
      toast.push('Error', 'An error occurred while joining the program.', 'error')
    },
  })

  const handleJoin = async () => {
    await joinProgram()
    // Exception (graphql) errors are handled by the mutation's 'onError' event.
  }

  // Rendering the invitation card with tenant information and action buttons
  return (
    <div className={'w-full'}>
      <Card
        headerIcon={<Icon name={'briefcase'} className={'translate-y-[-2.5px]'} />}
        header={program.name}
        bodyClass={'flex flex-col gap-3'}
        headerDivider={true}
        footer={
          <div className={'flex justify-end w-full items-center'}>
            <Button
              loading={loading}
              onClick={handleJoin}
              color={'breakerBay'}
              size={'md'}
              iconLocation={'right'}
              label={'Join'}
              variant={'plain'}
              icon={<Icon name={'arrow-right'} color={'breakerBay'} />}
            />
          </div>
        }
        headerClass={'text-lg font-semibold mb-[20px]'}
        footerClass={'text-blue-500 font-bold'}>
        <p>{program.process.name}</p>
        <div className={'flex flex-col gap-2'}>
          <p className={'font-normal body-sm text-gray-dark'}>
            { /* Todo: Make sure People and Person work with pluralize */ }
            {numberOfPeople > 0 ? `${numberOfPeople} ${pluralize('People', numberOfPeople)}` : ''}
          </p>
          <Avatar.Group chained maxCount={6} omittedAvatarTooltip omittedAvatarProps={{ size: 'sm' }}>
            {/* Iterating over users to show avatar in a group for each program */}
            {people.map(({ id, person }) => (
              <Tooltip title={`${person.name} | ${person.email}`}>
                <Avatar
                  shape={'circle'}
                  size={'sm'}
                  src={person.profileImage?.url}
                  name={person.name}
                  key={`program:${program.id}-member:${id}`}
                />
              </Tooltip>
            ))}
          </Avatar.Group>
        </div>
      </Card>
    </div>
  )
}

const TenantPrograms = () => {
  const { user } = useAuth()
  const tenant = user?.tenants[0]?.tenant

  const navigate = useNavigate()

  const { data, loading } = useProgramsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      where: {
        tenant: {
          id: {
            equals: tenant?.id || '',
          },
        },
      },
    },
  })

  return loading ? (
    <div className={'h-full flex justify-center items-center'}>
      <Indicator size={30} indicator={'loader'} isSpinning />
    </div>
  ) : (
    <div className={'pt-[100px] flex flex-col'}>
      <div className={'w-full max-w-[319px] p-[20px] self-center justify-self-center'}>
        <div className={'text-center'}>
          <h2>Join Programs</h2>
          <p className={'body-md'}>
            You may join any of the following programs to get started, or create a new program. You can always join
            these programs later.
          </p>
          <div className="h-[44px]" />
        </div>
        <div className={'flex flex-col justify-center items-center gap-[20px]'}>
          {/* Iterating over invitations to render each as a Card with properties and methods */}
          {data?.programs.list.map((program) => {
            return <ProgramCard program={program} key={program.id} />
          })}
        </div>
        <div className="h-[20px]" />
        <div className={'flex flex-col justify-center gap-2'}>
          <Button
            onClick={() => {
              navigate('/new-program')
            }}
            type={'submit'}
            size={'sm'}
            label={'Create new'}
            block
            variant={'solid'}
          />
        </div>
      </div>
    </div>
  )
}

export default TenantPrograms
