import React, { FC, useRef, MouseEvent } from 'react'
import AvatarGroup, { AvatarGroupProps } from './AvatarGroup'
import classNames from 'classnames'

type Size = 'sm' | 'md' | 'lg' | number
type Shape = 'rounded' | 'square' | 'circle'

export type AvatarProps = {
  shape?: Shape
  size?: Size
  icon?: React.ReactNode
  src?: string | React.ReactElement
  srcSet?: string
  alt?: string
  name?: string
  grouped?: boolean
  style?: React.CSSProperties
  children?: React.ReactNode
  overlap?: boolean
  onClick?: (event: MouseEvent) => void
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  className?: string
}

const sizeMapping: { [key in Size]: string } = {
  sm: '34px',
  md: '44px',
  lg: '69px',
}

const shapeMapping: { [key in Shape]: string } = {
  rounded: '7px',
  square: '0',
  circle: '50%',
}

const fontMapping: { [key in Size]: string } = {
  sm: '14px',
  md: '16px',
  lg: '22px',
}

const isSizeNumber = (size: Size): size is number => typeof size === 'number'

// Index Component
const Avatar: FC<AvatarProps> & { Group: FC<AvatarGroupProps> } = ({
  shape = 'circle',
  size = 'md',
  icon,
  src,
  srcSet,
  alt,
  name,
  grouped = false,
  overlap = false,
  children,
  onClick,
  className,
  ...rest
}) => {
  const avatarSize = isSizeNumber(size) ? `${size}px` : sizeMapping[size]
  const borderRadius = shapeMapping[shape]
  const fontSize = isSizeNumber(size) ? size * 0.42 : fontMapping[size]
  const avatarRef = useRef<HTMLImageElement | null>(null)

  const commonClasses = `flex items-center justify-center bg-gray-200 text-gray-900 font-semibold leading-none font-figtree ${
    overlap ? '-ml-7' : ''
  }`

  const commonStyles = {
    borderRadius,
    width: avatarSize,
    height: avatarSize,
    fontSize: `${fontSize}px`,
    border: grouped ? '2px solid white' : undefined,
    cursor: onClick ? 'pointer' : undefined,
  }

  if (typeof src === 'string') {
    return (
      <img
        {...rest}
        onClick={onClick}
        ref={avatarRef}
        src={src}
        srcSet={srcSet}
        alt={alt || 'Index'}
        className={classNames(commonClasses, className)}
        style={commonStyles}
      />
    )
  }

  if (React.isValidElement(src)) {
    const DivWithRef = React.forwardRef((props, ref) => (
      <div
        {...rest}
        onClick={onClick}
        ref={ref as React.LegacyRef<HTMLDivElement>}
        className={classNames(commonClasses, className)}
        style={commonStyles}>
        {src}
      </div>
    ))
    return <DivWithRef ref={avatarRef} />
  }

  if (icon) {
    const DivWithRef = React.forwardRef((props, ref) => (
      <div
        {...rest}
        onClick={onClick}
        ref={ref as React.LegacyRef<HTMLDivElement>}
        className={classNames(commonClasses, className)}
        style={commonStyles}>
        {icon}
      </div>
    ))
    return <DivWithRef ref={avatarRef} />
  }

  const nameParts = name?.split(' ') || []
  let firstName
  let lastName
  if (nameParts.length === 3) {
    firstName = nameParts[0]
    lastName = nameParts[2]
  } else if (nameParts.length === 1) {
    firstName = nameParts[0]
  } else {
    firstName = nameParts[0]
    lastName = nameParts[1]
  }
  const avatarInitials = `${firstName?.charAt(0)}${lastName ? lastName.charAt(0) : ''}`

  return (
    <div
      {...rest}
      onClick={onClick}
      ref={avatarRef as React.LegacyRef<HTMLDivElement>}
      className={classNames(commonClasses, className)}
      style={commonStyles}>
      {children || avatarInitials}
    </div>
  )
}

Avatar.Group = AvatarGroup

export default Avatar
