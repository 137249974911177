import React, { useEffect, useMemo } from 'react'
import { Alert, Button, Form, Input } from '../../../components/ui'
import { Field, Formik } from 'formik'
import { AnimatePresence, motion } from 'framer-motion'
import { validateEmail } from '../../../utils/email'
import { ConfirmationPurpose, ConfirmationType } from '../../../graphql'
//import { useNavigate } from 'react-router-dom'
import useConfirmation from '../../../hooks/useConfirmation'
import useAuth from '../../../hooks/useAuth'

const ConfirmEmailForm = () => {
  const [enteredEmail, setEnteredEmail] = React.useState('')
  const [emailValue, setEmailValue] = React.useState('')
  const [codeValue, setCodeValue] = React.useState('')
  const [emailError, setEmailError] = React.useState<undefined | string>(undefined)
  const [codeError, setCodeError] = React.useState<undefined | string>(undefined)
  const [confirmationOverflow, setConfirmationOverflow] = React.useState<'hidden' | 'visible'>('hidden')

  const confirmation = useConfirmation()
  const auth = useAuth()

  //const navigate = useNavigate()

  useEffect(() => {
    if (auth.confirmation) {
      // If confirmation details are already stored in local storage, automatically navigate to onboarding screen
      //navigate('/onboarding')
    }
  }, [auth.confirmation])

  const confirmEmail = useMemo(() => {
    setEmailError(undefined)
    return enteredEmail !== '' && enteredEmail === emailValue
  }, [enteredEmail, emailValue])

  useEffect(() => {
    if (!confirmEmail && enteredEmail !== '') {
      setEnteredEmail('')
    }
  }, [confirmEmail, enteredEmail])

  useEffect(() => {
    setCodeError(undefined)
  }, [codeValue])

  return (
    <Formik
      initialValues={{ email: '' }}
      onSubmit={async () => {
        if (!confirmEmail) {
          if (validateEmail(emailValue)) {
            if (emailError) {
              setEmailError(undefined)
            }
            setEnteredEmail(emailValue)
            await confirmation.send({
              variables: {
                data: {
                  type: ConfirmationType.Email,
                  value: emailValue,
                  purpose: ConfirmationPurpose.SignUp,
                },
              },
            })
          } else {
            setEmailError('Invalid email address')
          }
        } else {
          if (!codeValue) {
            setCodeError('Code is required')
          } else {
            await confirmation.receive({
              code: codeValue,
              // todo: implement stay signed in option for sign in codes
            })
          }
        }
      }}>
      {() => (
        <div className="w-full max-w-[319px] self-center justify-self-center">
          <Form.Container>
            <div>
              <h2>Sign Up</h2>
              <p className={'body-md'}>Enter your email address.</p>
            </div>
            <Form.Item size={'sm'} errorMessage={emailError} invalid={!!emailError}>
              <Field
                size={'sm'}
                name={'email'}
                placeholder={'Email'}
                value={emailValue}
                onChange={(e: { target: { value: string } }) => setEmailValue(e.target.value)}
                component={Input}
              />
            </Form.Item>
            <AnimatePresence>
              {confirmation.sendResponse?.__typename === 'SendConfirmationSuccess' && confirmEmail && (
                <motion.div
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: 'auto' }}
                  exit={{ opacity: 0, height: 0 }}
                  onEnded={(test) => {
                    setConfirmationOverflow('visible')
                  }}
                  onAnimationComplete={(test) => {
                    setConfirmationOverflow('visible')
                  }}
                  transition={{ duration: 0.3, type: 'tween' }}
                  className={`overflow-${confirmationOverflow}`}>
                  <p className={'body-md mb-[20px]'}>{confirmation.sendResponse?.message}</p>
                  <Form.Item size={'sm'} errorMessage={codeError} invalid={!!codeError}>
                    <Field
                      size={'sm'}
                      name={'code'}
                      placeholder={'Paste code'}
                      value={codeValue}
                      onChange={(e: { target: { value: string } }) => setCodeValue(e.target.value)}
                      component={Input}
                    />
                  </Form.Item>
                </motion.div>
              )}
              {confirmation.receiveResponse?.__typename?.endsWith('Error') && confirmEmail && (
                <motion.div
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: 'auto' }}
                  exit={{ opacity: 0, height: 0 }}
                  transition={{ duration: 0.3, type: 'tween' }}
                  className={'overflow-hidden'}>
                  <Alert label={confirmation.receiveResponse?.message} closeable={false} type={'danger'} />
                </motion.div>
              )}
            </AnimatePresence>
            <Button
              loading={confirmation.loading}
              block
              label={
                confirmEmail
                  ? confirmation.data?.purpose === ConfirmationPurpose.SignIn
                    ? 'Continue with sign in code'
                    : 'Create new account'
                  : 'Continue with email'
              }
              size={'sm'}
              variant={'solid'}
            />
          </Form.Container>
        </div>
      )}
    </Formik>
  )
}

export default ConfirmEmailForm
