import React from 'react'
import { Steps } from '../../../components/ui/index'

const StepsExample = () => {
  return (
    <div className="p-4">
      <h2 className="text-xl mb-4">Horizontal Stepper</h2>
      <div className="mb-8">
        <Steps clickable={true}>
          <Steps.Item clickable={true} color={'blue-600'} title={'Login'} />
          <Steps.Item clickable={true} color={'blue-600'} title={'Order Placed'} />
          <Steps.Item clickable={true} color={'blue-600'} title={'In Review'} />
          <Steps.Item clickable={true} color={'blue-600'} title={'Approved'} />
        </Steps>
        <Steps>
          <Steps.Item color={'blue-600'} title={'Login'} status={'in-progress'} />
          <Steps.Item color={'blue-600'} title={'Order Placed'} />
          <Steps.Item color={'blue-600'} title={'In Review'} />
          <Steps.Item color={'blue-600'} title={'Approved'} />
        </Steps>
        <Steps>
          <Steps.Item color={'blue-600'} title={'Login'} status={'complete'} />
          <Steps.Item color={'blue-600'} title={'Order Placed'} status={'in-progress'} />
          <Steps.Item color={'blue-600'} title={'In Review'} />
          <Steps.Item color={'blue-600'} title={'Approved'} />
        </Steps>
        <Steps>
          <Steps.Item color={'blue-600'} title={'Login'} status={'complete'} />
          <Steps.Item color={'blue-600'} title={'Order Placed'} status={'complete'} />
          <Steps.Item color={'blue-600'} title={'In Review'} status={'in-progress'} />
          <Steps.Item color={'blue-600'} title={'Approved'} />
        </Steps>
        <Steps>
          <Steps.Item color={'blue-600'} title={'Login'} status={'complete'} />
          <Steps.Item color={'blue-600'} title={'Order Placed'} status={'complete'} />
          <Steps.Item color={'blue-600'} title={'In Review'} status={'complete'} />
          <Steps.Item color={'blue-600'} title={'Approved'} status={'in-progress'} />
        </Steps>
        <Steps>
          <Steps.Item color={'blue-600'} title={'Login'} status={'complete'} />
          <Steps.Item color={'blue-600'} title={'Order Placed'} status={'complete'} />
          <Steps.Item color={'blue-600'} title={'In Review'} status={'complete'} />
          <Steps.Item color={'blue-600'} title={'Approved'} status={'complete'} />
        </Steps>
      </div>
      <h2 className="text-xl mb-4">Vertical Stepper</h2>
      <div className={'flex'}>
        <Steps vertical>
          <Steps.Item color={'orange-600'} title={'Login'} />
          <Steps.Item color={'orange-600'} title={'Order Placed'} />
          <Steps.Item color={'orange-600'} title={'In Review'} />
          <Steps.Item color={'orange-600'} title={'Approved'} />
        </Steps>
        <Steps vertical>
          <Steps.Item color={'orange-600'} title={'Login'} status={'in-progress'} />
          <Steps.Item color={'orange-600'} title={'Order Placed'} />
          <Steps.Item color={'orange-600'} title={'In Review'} />
          <Steps.Item color={'orange-600'} title={'Approved'} />
        </Steps>
        <Steps vertical>
          <Steps.Item color={'orange-600'} title={'Login'} status={'complete'} />
          <Steps.Item color={'orange-600'} title={'Order Placed'} status={'in-progress'} />
          <Steps.Item color={'orange-600'} title={'In Review'} />
          <Steps.Item color={'orange-600'} title={'Approved'} />
        </Steps>
        <Steps vertical>
          <Steps.Item color={'orange-600'} title={'Login'} status={'complete'} />
          <Steps.Item color={'orange-600'} title={'Order Placed'} status={'complete'} />
          <Steps.Item color={'orange-600'} title={'In Review'} status={'in-progress'} />
          <Steps.Item color={'orange-600'} title={'Approved'} />
        </Steps>
        <Steps vertical>
          <Steps.Item color={'orange-600'} title={'Login'} status={'complete'} />
          <Steps.Item color={'orange-600'} title={'Order Placed'} status={'complete'} />
          <Steps.Item color={'orange-600'} title={'In Review'} status={'complete'} />
          <Steps.Item color={'orange-600'} title={'Approved'} status={'in-progress'} />
        </Steps>
        <Steps vertical>
          <Steps.Item color={'orange-600'} title={'Login'} status={'complete'} />
          <Steps.Item color={'orange-600'} title={'Order Placed'} status={'complete'} />
          <Steps.Item color={'orange-600'} title={'In Review'} status={'complete'} />
          <Steps.Item color={'orange-600'} title={'Approved'} status={'complete'} />
        </Steps>
      </div>
    </div>
  )
}

export default StepsExample
