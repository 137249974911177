import React from 'react'
import appConfig from '../../../../config/app'
import { Navigate, Outlet } from 'react-router-dom'
import useAuth from '../../../../hooks/useAuth'

const { authenticatedEntryPath, onboardingEntryPath } = appConfig

const PublicRoute = () => {
  const { authenticated, user } = useAuth()

  return authenticated && user?.tenants.length && user?.programs.length ? (
    <Navigate to={authenticatedEntryPath} />
  ) : authenticated ? (
    <Navigate to={onboardingEntryPath} />
  ) : (
    <Outlet />
  )
}

export default PublicRoute
